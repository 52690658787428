import { Settings as AttractionSettings } from "@twocontinents/dashboard/attractions/attraction-settings";
import { AttractionsPage } from "@twocontinents/dashboard/attractions/attractions-page";
import { OrderPage } from "@twocontinents/dashboard/attractions/order-page";
import { OrdersPage } from "@twocontinents/dashboard/attractions/orders-page";
import { TicketsGenerator } from "@twocontinents/dashboard/attractions/tickets-generator";
import { TicketsHistory } from "@twocontinents/dashboard/attractions/tickets-history";
import { TourCreator } from "@twocontinents/dashboard/attractions/tour-creator";
import { TourManagement } from "@twocontinents/dashboard/attractions/tour-management";
import { ToursListing } from "@twocontinents/dashboard/attractions/tours-listing";
import { BusinessPartnersIndexPage } from "@twocontinents/dashboard/business-partners/index";
import { BusinessPartnerShowPage } from "@twocontinents/dashboard/business-partners/show";
import { CouponsManagement } from "@twocontinents/dashboard/coupons-management";
import { FinancialLogsIndexPage } from "@twocontinents/dashboard/financial-log/index";
import { FinancialLogShowPage } from "@twocontinents/dashboard/financial-log/show";
import { PaymentLinkGenerator } from "@twocontinents/dashboard/payment-link-generator";
import { ReviewsManagement } from "@twocontinents/dashboard/reviews-management";
import { UserRole, routes } from "@twocontinents/dashboard/shared";
import { ProtectedRoute } from "@twocontinents/dashboard/shared/auth";
import { SignIn } from "@twocontinents/dashboard/sign-in";
import { StatisticsPage } from "@twocontinents/dashboard/statistics";
import { ApartmentIndexPage } from "@twocontinents/dashboard/stays/apartments/index";
import { ApartmentShowPage } from "@twocontinents/dashboard/stays/apartments/show";
import { BookingOrdersIndexPage } from "@twocontinents/dashboard/stays/orders/index";
import { BookingOrderShowPage } from "@twocontinents/dashboard/stays/orders/show";
import { CreateInvoicePage } from "@twocontinents/dashboard/travel-agencies/invoices/create";
import { InvoicesTab } from "@twocontinents/dashboard/travel-agencies/invoices/index";
import { OfferBundleCreatePage } from "@twocontinents/dashboard/travel-agencies/offer-bundle/create";
import { OfferBundleIndexPage } from "@twocontinents/dashboard/travel-agencies/offer-bundle/index";
import { OfferBundleShowPage } from "@twocontinents/dashboard/travel-agencies/offer-bundle/show";
import { TravelAgencyCreatePage } from "@twocontinents/dashboard/travel-agencies/travel-agency/create";
import { TravelAgencyIndexPage } from "@twocontinents/dashboard/travel-agencies/travel-agency/index";
import { TravelAgencyShowPage } from "@twocontinents/dashboard/travel-agencies/travel-agency/show";
import queryString from "query-string";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

const ProtectedRoutesElement = () => {
  return (
    <QueryParamProvider
      adapter={ReactRouter6Adapter}
      options={{
        searchStringToObject: queryString.parse,
        objectToSearchString: queryString.stringify,
      }}
    >
      <ProtectedRoute />
    </QueryParamProvider>
  );
};

const PUBLIC_ROUTES = [
  {
    path: routes.login,
    element: <SignIn />,
  },
];

const PROTECTED_ROUTES = [
  {
    path: "/",
    element: <ProtectedRoutesElement />,
    children: [
      {
        path: routes.home,
        element: <StatisticsPage />,
      },
      {
        path: routes.attractions.index.index,
        element: <AttractionsPage />,
        index: true,
      },
      {
        path: routes.attractions.orders.index,
        element: <OrdersPage />,
      },
      {
        path: routes.attractions.orders.show.path,
        element: <OrderPage />,
      },
      {
        path: routes.attractions.index.show.path,
        element: <AttractionSettings />,
      },
      {
        path: routes.attractions.tours.index,
        element: <ToursListing />,
      },
      {
        path: routes.attractions.tours.show.path,
        element: <TourManagement />,
      },
      {
        path: routes.attractions.tours.create,
        element: <TourCreator />,
      },
      {
        path: routes.coupons.index,
        element: <CouponsManagement />,
      },
      {
        path: routes.financialLog.show.path,
        element: <FinancialLogShowPage />,
      },
      {
        path: routes.financialLog.index,
        element: <FinancialLogsIndexPage />,
      },
      {
        path: routes.reviews.index,
        element: <ReviewsManagement />,
      },
      {
        path: routes.businessPartners.index,
        element: <ProtectedRoute roles={[UserRole.ROOT]} />,
        children: [
          {
            path: routes.businessPartners.show.path,
            element: <BusinessPartnerShowPage />,
          },
          {
            path: routes.businessPartners.index,
            element: <BusinessPartnersIndexPage />,
          },
        ],
      },
      {
        path: routes.stays.apartments.index,
        element: <ApartmentIndexPage />,
      },
      {
        path: routes.stays.apartments.show.path,
        element: <ApartmentShowPage />,
      },
      {
        path: routes.stays.orders.index,
        element: <BookingOrdersIndexPage />,
      },
      {
        path: routes.stays.orders.show.path,
        element: <BookingOrderShowPage />,
      },
      {
        path: "/travel-agencies",
        element: <ProtectedRoute roles={[UserRole.ROOT]} />,
        children: [
          {
            path: routes.travelAgencies.invoices.index,
            element: <InvoicesTab />,
          },
          {
            path: routes.travelAgencies.invoices.create,
            element: <CreateInvoicePage />,
          },
          {
            path: routes.travelAgencies.show.path,
            element: <TravelAgencyShowPage />,
          },
          {
            path: routes.travelAgencies.index,
            element: <TravelAgencyIndexPage />,
          },
          {
            path: routes.travelAgencies.create,
            element: <TravelAgencyCreatePage />,
          },
          {
            path: routes.travelAgencies.offerBundle.index,
            element: <OfferBundleIndexPage />,
          },
          {
            path: routes.travelAgencies.offerBundle.create,
            element: <OfferBundleCreatePage />,
          },
          {
            path: routes.travelAgencies.offerBundle.show.path,
            element: <OfferBundleShowPage />,
          },
        ],
      },
      {
        path: routes.paymentLinkGenerator,
        element: <PaymentLinkGenerator />,
      },
      {
        path: routes.ticketGenerator.generator,
        element: <TicketsGenerator />,
      },
      {
        path: routes.ticketGenerator.history,
        element: <TicketsHistory />,
      },
    ],
  },
];

export const Router = () => {
  const router = createBrowserRouter([...PUBLIC_ROUTES, ...PROTECTED_ROUTES]);

  return <RouterProvider router={router} />;
};
