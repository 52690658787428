import {
  Form,
  FormItem,
  Input,
  SettingCard,
} from "@twocontinents/dashboard/shared";
import { CurrencyCodeSelect } from "@twocontinents/dashboard/shared";
import { CurrencyCode, Money } from "@twocontinents/shared";

import { useAdditionalBedsSettings } from "./use-additional-beds-settings";

interface AdditionalBedsSettingsProps {
  currentAdditionalBedPrice: Money;
  maxAdditionalBedsAmount: number;
  apartmentId: number;
}

export const AdditionalBedsSettings = ({
  apartmentId,
  currentAdditionalBedPrice,
  maxAdditionalBedsAmount,
}: AdditionalBedsSettingsProps) => {
  const { form, onSubmit, isPending } = useAdditionalBedsSettings(
    apartmentId,
    maxAdditionalBedsAmount,
    currentAdditionalBedPrice,
  );

  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>Extra guests settings</SettingCard.Title>
        <SettingCard.Description>
          Change apartment's extra guests settings
        </SettingCard.Description>
      </SettingCard.Header>
      <Form {...form}>
        <form onSubmit={onSubmit}>
          <Form.Fields>
            <Form.Field
              control={form.control}
              name="maxAdditionalBedsAmount"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label className="min-w-36">
                      Extra Guests limit
                    </FormItem.Label>
                    <FormItem.Control>
                      <Input {...field} type="number" className="max-w-md" />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            <Form.Field
              control={form.control}
              name="additionalBedPrice.amount"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label className="min-w-36">
                      Extra guest price
                    </FormItem.Label>
                    <FormItem.Control>
                      <Input {...field} type="number" className="max-w-md" />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            <Form.Field
              control={form.control}
              name="additionalBedPrice.currency"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label className="min-w-36">
                      Currency
                    </FormItem.Label>
                    <FormItem.Control>
                      <CurrencyCodeSelect
                        value={field.value}
                        currencies={Object.values(CurrencyCode)}
                        onValueChange={field.onChange}
                      />
                    </FormItem.Control>
                  </FormItem.Row>
                </FormItem>
              )}
            />
          </Form.Fields>
          <SettingCard.Footer>
            <SettingCard.Description>
              This setting will change extra guests price and limit for this
              apartment
            </SettingCard.Description>
            <SettingCard.Button isLoading={isPending}>
              Submit
            </SettingCard.Button>
          </SettingCard.Footer>
        </form>
      </Form>
    </SettingCard>
  );
};
