import {
  Form,
  FormItem,
  Input,
  SettingCard,
} from "@twocontinents/dashboard/shared";

import { useMaxGuestsAmountSettings } from "./use-max-guests-amount-settings";

interface DefaultStaysAmountSettingsProps {
  apartmentId: number;
  currentMaxGuestsAmount: number;
  currentMaxAdultsAmount: number;
}

export const MaxGuestsAmountSettings = ({
  apartmentId,
  currentMaxGuestsAmount,
  currentMaxAdultsAmount,
}: DefaultStaysAmountSettingsProps) => {
  const { form, onSubmit, isPending } = useMaxGuestsAmountSettings(
    apartmentId,
    currentMaxGuestsAmount,
    currentMaxAdultsAmount,
  );

  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>Max guests amount settings</SettingCard.Title>
        <SettingCard.Description>
          Change apartment's max guests amount settings
        </SettingCard.Description>
      </SettingCard.Header>
      <Form {...form}>
        <form onSubmit={onSubmit}>
          <Form.Fields>
            <Form.Field
              control={form.control}
              name="maxGuestsAmount"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label className="min-w-52">
                      Maximum guests amount
                    </FormItem.Label>
                    <FormItem.Control>
                      <Input {...field} type={"number"} className="max-w-md" />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            <Form.Field
              control={form.control}
              name="maxAdultsAmount"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label className="min-w-52">
                      Maximum adults amount
                    </FormItem.Label>
                    <FormItem.Control>
                      <Input {...field} type={"number"} className="max-w-md" />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
          </Form.Fields>
          <SettingCard.Footer>
            <SettingCard.Description>
              This setting will change the maximum guests amount and maximum
              adults amount
            </SettingCard.Description>
            <SettingCard.Button isLoading={isPending}>
              Submit
            </SettingCard.Button>
          </SettingCard.Footer>
        </form>
      </Form>
    </SettingCard>
  );
};
