/* eslint-disable max-lines-per-function */
import { useState } from "react";

import { ALL_ITEMS_ID } from "../../constants";

export interface ApartmentOption {
  id: number;
  description: string;
}

const defaultApartmentOption: ApartmentOption = {
  description: "All apartments",
  id: ALL_ITEMS_ID,
};

export const useApartmentCombobox = (
  apartments: ApartmentOption[],
  setSelectedOption: (value: ApartmentOption) => void,
  selectedId?: number,
) => {
  const [open, setOpen] = useState(false);

  const onSelect = (value: string) => {
    const foundApartment = apartments?.find(
      (apartment) =>
        apartment.description.toLowerCase() === value.toLowerCase(),
    );

    if (!foundApartment) {
      setSelectedOption(defaultApartmentOption);
      setOpen(false);
      return;
    }

    setSelectedOption({
      id: foundApartment.id,
      description: foundApartment.description,
    });
    setOpen(false);
  };

  const selectedOption =
    apartments?.find((apartment) => apartment.id === selectedId) ??
    defaultApartmentOption;

  const apartmentsOptions: ApartmentOption[] = [
    defaultApartmentOption,
    ...(apartments
      ? apartments.map((apartment) => ({
          id: apartment.id,
          description: apartment.description,
        }))
      : []),
  ];

  return {
    open,
    setOpen,
    onSelect,
    apartmentsOptions,
    defaultApartmentOption,
    selectedOption,
  };
};
