import { z } from "zod";

const isServer = typeof window === "undefined";
const isVite = typeof process === "undefined" && !isServer;

declare global {
  interface ImportMeta {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    env: Record<string, string>;
  }
}

const envSchema = z.object({
  GOOGLE_MAPS_API_KEY: z.string().min(3),
  GOOGLE_PLACES_API_KEY: z.string().min(3),
  GOOGLE_MAP_ID: z.string().min(3),
  GOOGLE_MAP_TYPE_ID: z.string().min(3),
  PROPERTIES_APP_URL: z.string().url().min(3),
  SHOP_APP_URL: z.string().url().min(3),
  CLOUDFLARE_CDN_URL: z.string().url().min(3),
  DASHBOARD_APP_URL: z.string().url().min(3),
  API_URL: z.string().url().min(3),
  ENVIRONMENT: z.string(),
  DEPLOYMENT_ENVIRONMENT: z.string(),
  STRAPI_URL: z.string().url().nullable(),
  REVALIDATION_TOKEN: z.string().min(3).nullable(),
  STRAPI_ACCESS_TOKEN: z.string().min(3).nullable(),
  GEOAPIFY_API_KEY: z.string().min(3).nullable(),
  RECAPTCHA_SITE_KEY: z.string().min(3),
  RECAPTCHA_SECRET_KEY: z.string().min(3).nullable(),
  RECAPTCHA_API_URL: z.string().url().nullable(),
  SENTRY_DSN: z.string().min(3).nullable(),
  NEXT_AUTH_TOKEN_VALIDITY_IN_SECONDS: z.coerce.number().min(3).nullable(),
  MAILERLITE_API_KEY: z.string().min(3).nullable(),
  MAILERLITE_ESTATE_GROUP_ID: z.string().min(3).nullable(),
  MAILERLITE_SHOP_GROUP_ID: z.string().min(3).nullable(),
  PROPERTIES_GTM_ID: z.string().min(1).nullable(),
  SHOP_GTM_ID: z.string().min(1).nullable(),
  COMMIT_SHA: z.string().nullable(),
  VERSION: z.string().nullable(),
  BRANCH_NAME: z.string().nullable(),
  HOTJAR_ID: z.coerce.number().nullable(),
  HOTJAR_VERSION: z.coerce.number().nullable(),
});

export const env = isVite
  ? envSchema.parse({
      GOOGLE_MAPS_API_KEY: import.meta.env["VITE_GOOGLE_MAPS_API_KEY"],
      GOOGLE_PLACES_API_KEY: import.meta.env["VITE_GOOGLE_PLACES_API_KEY"],
      GOOGLE_MAP_ID: import.meta.env["VITE_GOOGLE_MAP_ID"],
      GOOGLE_MAP_TYPE_ID: import.meta.env["VITE_GOOGLE_MAP_TYPE_ID"],
      PROPERTIES_APP_URL: import.meta.env["VITE_PROPERTIES_APP_URL"],
      SHOP_APP_URL: import.meta.env["VITE_SHOP_APP_URL"],
      DASHBOARD_APP_URL: import.meta.env["VITE_DASHBOARD_APP_URL"],
      API_URL: import.meta.env["VITE_API_URL"],
      ENVIRONMENT: import.meta.env["VITE_ENVIRONMENT"],
      DEPLOYMENT_ENVIRONMENT: import.meta.env["VITE_DEPLOYMENT_ENVIRONMENT"],
      RECAPTCHA_SITE_KEY: import.meta.env["VITE_RECAPTCHA_SITE_KEY"],
      CLOUDFLARE_CDN_URL: import.meta.env["VITE_CLOUDFLARE_CDN_URL"],
      STRAPI_URL: null,
      STRAPI_ACCESS_TOKEN: null,
      REVALIDATION_TOKEN: null,
      MAILERLITE_API_KEY: null,
      GEOAPIFY_API_KEY: null,
      RECAPTCHA_SECRET_KEY: null,
      RECAPTCHA_API_URL: null,
      SENTRY_DSN: null,
      NEXT_AUTH_TOKEN_VALIDITY_IN_SECONDS: null,
      PROPERTIES_GTM_ID: null,
      SHOP_GTM_ID: null,
      MAILERLITE_ESTATE_GROUP_ID: null,
      MAILERLITE_SHOP_GROUP_ID: null,
      COMMIT_SHA: null,
      VERSION: null,
      BRANCH_NAME: null,
      HOTJAR_ID: null,
      HOTJAR_VERSION: null,
    })
  : envSchema.parse({
      GOOGLE_MAPS_API_KEY: process.env["NEXT_PUBLIC_GOOGLE_MAPS_API_KEY"],
      GOOGLE_PLACES_API_KEY: process.env["NEXT_PUBLIC_GOOGLE_PLACES_API_KEY"],
      GOOGLE_MAP_ID: process.env["NEXT_PUBLIC_GOOGLE_MAP_ID"],
      GOOGLE_MAP_TYPE_ID: process.env["NEXT_PUBLIC_GOOGLE_MAP_TYPE_ID"],
      PROPERTIES_APP_URL: process.env["NEXT_PUBLIC_PROPERTIES_APP_URL"],
      SHOP_APP_URL: process.env["NEXT_PUBLIC_SHOP_APP_URL"],
      CLOUDFLARE_CDN_URL: process.env["NEXT_PUBLIC_CLOUDFLARE_CDN_URL"],
      DASHBOARD_APP_URL: process.env["NEXT_PUBLIC_DASHBOARD_APP_URL"],
      API_URL: process.env["NEXT_PUBLIC_API_URL"],
      ENVIRONMENT: process.env["NEXT_PUBLIC_ENVIRONMENT"],
      DEPLOYMENT_ENVIRONMENT: process.env["NEXT_PUBLIC_DEPLOYMENT_ENVIRONMENT"],
      SENTRY_DSN: process.env["NEXT_PUBLIC_SENTRY_DSN"],
      HOTJAR_ID: process.env["NEXT_PUBLIC_HOTJAR_ID"],
      HOTJAR_VERSION: process.env["NEXT_PUBLIC_HOTJAR_VERSION"],
      STRAPI_URL: isServer ? process.env["STRAPI_URL"] : null,
      STRAPI_ACCESS_TOKEN: isServer ? process.env["STRAPI_ACCESS_TOKEN"] : null,
      REVALIDATION_TOKEN: isServer ? process.env["REVALIDATION_TOKEN"] : null,
      GEOAPIFY_API_KEY: process.env["NEXT_PUBLIC_GEOAPIFY_API_KEY"],
      RECAPTCHA_SITE_KEY: process.env["NEXT_PUBLIC_RECAPTCHA_SITE_KEY"],
      RECAPTCHA_SECRET_KEY: isServer
        ? process.env["RECAPTCHA_SECRET_KEY"]
        : null,
      RECAPTCHA_API_URL: isServer ? process.env["RECAPTCHA_API_URL"] : null,
      NEXT_AUTH_TOKEN_VALIDITY_IN_SECONDS: isServer
        ? process.env["NEXT_AUTH_TOKEN_VALIDITY_IN_SECONDS"]
        : null,
      MAILERLITE_API_KEY: isServer ? process.env["MAILERLITE_API_KEY"] : null,
      MAILERLITE_ESTATE_GROUP_ID: isServer
        ? process.env["MAILERLITE_ESTATE_GROUP_ID"]
        : null,
      MAILERLITE_SHOP_GROUP_ID: isServer
        ? process.env["MAILERLITE_SHOP_GROUP_ID"]
        : null,
      PROPERTIES_GTM_ID: process.env["NEXT_PUBLIC_PROPERTIES_GTM_ID"],
      SHOP_GTM_ID: process.env["NEXT_PUBLIC_SHOP_GTM_ID"],
      COMMIT_SHA: isServer ? process.env["COMMIT_SHA"] : null,
      VERSION: isServer ? process.env["VERSION"] : null,
      BRANCH_NAME: isServer ? process.env["BRANCH_NAME"] : null,
    });
