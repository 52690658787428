"use client";

import { GoogleMapProps } from "@react-google-maps/api";
import { Coordinates, MapGestureHandling, env } from "@twocontinents/shared";
import { Map } from "@vis.gl/react-google-maps";
import { CSSProperties, PropsWithChildren, useEffect } from "react";
import { useDebounceCallback } from "usehooks-ts";

import { Marker } from "./marker";

interface BaseGoogleMapsMapProps extends PropsWithChildren, GoogleMapProps {
  center?: Coordinates;
  mapContainerStyle?: CSSProperties;
  addMarker?: (latitude: number, longitude: number) => void;
  mapGestureHandling?: MapGestureHandling;
  map: google.maps.Map | null;
  minimalUI?: boolean;
  zoom?: number;
  dubaiRestriction?: boolean;
}

const DEFAULT_ZOOM = 10;
const DUBAI_COORDINATES = { lat: 25.0657, lng: 55.1713 };
const DUBAI_BOUNDS = {
  north: 25.3585,
  south: 24.7921,
  west: 54.8941,
  east: 55.4634,
};

export const BaseGoogleMapsMap = ({
  center = DUBAI_COORDINATES,
  mapContainerStyle,
  children,
  mapGestureHandling,
  addMarker,
  map,
  minimalUI,
  zoom,
  dubaiRestriction,
  ...props
}: BaseGoogleMapsMapProps) => {
  const debouncedHandleZoomChange = useDebounceCallback(
    (map: google.maps.Map | null, zoom) => {
      if (map && zoom !== undefined) {
        map.setZoom(zoom);
      }
    },
    500,
  );

  useEffect(() => {
    if (map) {
      map.setCenter(center);
    }
  }, [center, map]);

  useEffect(() => {
    if (map) {
      debouncedHandleZoomChange(map, zoom ?? DEFAULT_ZOOM);
    }
  }, [debouncedHandleZoomChange, map, center, zoom]);

  return (
    <Map
      mapId={env.GOOGLE_MAP_ID}
      mapTypeId={env.GOOGLE_MAP_TYPE_ID}
      defaultZoom={zoom ?? DEFAULT_ZOOM}
      defaultCenter={center}
      style={{
        ...mapContainerStyle,
        outline: "none",
      }}
      restriction={
        dubaiRestriction
          ? {
              latLngBounds: DUBAI_BOUNDS,
              strictBounds: false,
            }
          : undefined
      }
      gestureHandling={mapGestureHandling ?? "greedy"}
      zoomControl={minimalUI ?? true}
      scrollwheel={false}
      disableDoubleClickZoom={false}
      streetViewControl={false}
      rotateControl={false}
      disableDefaultUI={minimalUI ?? false}
      keyboardShortcuts={false}
      fullscreenControl={false}
      tilt={0}
      heading={0}
      {...props}
      onClick={(e) => {
        if (addMarker) {
          addMarker(e.detail.latLng?.lat ?? 0, e.detail.latLng?.lng ?? 0);
        }
      }}
    >
      {children}
    </Map>
  );
};

BaseGoogleMapsMap.Marker = Marker;
