import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";

import { GetAttractionReviewsFilters } from "../types";

const queryKey = (filters?: GetAttractionReviewsFilters) => [
  "private-attraction-reviews",
  filters ?? "all",
];

export const useGetAttractionReviews = (
  filters?: GetAttractionReviewsFilters,
) => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryKey: queryKey(filters),
    queryFn: () =>
      apiClient
        .GET("/private/reviews/attraction", {
          params: {
            query: filters,
          },
        })
        .then((response) => response.data),
  });

  return {
    paginatedReviews: data,
    isLoading,
  };
};

export const useInvalidateGetAttractionReviews = () => {
  const queryClient = useQueryClient();

  const invalidate = async () => {
    await queryClient.invalidateQueries({
      queryKey: ["private-attraction-reviews"],
    });
  };

  return {
    invalidate,
  };
};
