import {
  AttractionGroup,
  AttractionGroupSelect,
  Button,
  Checkbox,
  Form,
  FormItem,
  Input,
  SettingCard,
} from "@twocontinents/dashboard/shared";

import { Calendar } from "../calendar";
import { TimeSelect } from "../time-select";
import { useAvailabilitySettings } from "./use-availability-settings";

interface AvailabilitySettingsProps {
  attractionId: number;
  attractionGroups: AttractionGroup[];
}

export const AvailabilitySettings = ({
  attractionId,
  attractionGroups,
}: AvailabilitySettingsProps) => {
  const {
    form,
    onSubmit,
    isPending,
    formRef,
    mutatedAvailabilities,
    isGroupTimed,
    saveMutatedAvailabilities,
    onSubmitUnavailable,
    extraOptions,
    times,
    attractionVariants,
    activationLogsOpened,
    toggleActivationLogsOpened,
    getAvailableSlotsByTime,
    addButtonDisabled,
    selectedDate,
  } = useAvailabilitySettings(attractionGroups, attractionId);

  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>Availability settings</SettingCard.Title>
        <SettingCard.Description>
          Change group's availability
        </SettingCard.Description>
      </SettingCard.Header>
      <Form {...form}>
        <form onSubmit={onSubmit} ref={formRef}>
          <Form.Field
            control={form.control}
            name="groupId"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label>Group</FormItem.Label>
                  <FormItem.Control>
                    <AttractionGroupSelect
                      attractionGroups={attractionGroups}
                      onValueChange={field.onChange}
                    />
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Message />
              </FormItem>
            )}
          />
          <div className={"flex gap-40 items-center mt-10"}>
            <div className={"w-full max-w-md"}>
              <Form.Fields>
                <Form.Field
                  control={form.control}
                  name={`availability.date`}
                  render={({ field }) => (
                    <FormItem>
                      <FormItem.Row>
                        <FormItem.Label>Date</FormItem.Label>
                        <FormItem.Control>
                          <Input {...field} type={"date"} />
                        </FormItem.Control>
                      </FormItem.Row>
                      <FormItem.Message />
                    </FormItem>
                  )}
                />
                {isGroupTimed && (
                  <TimeSelect
                    key={selectedDate}
                    getAvailableSlotsByTime={getAvailableSlotsByTime}
                    times={times}
                  />
                )}
                <Form.Field
                  control={form.control}
                  name={`availability.slotsAvailable`}
                  render={({ field }) => (
                    <FormItem>
                      <FormItem.Row>
                        <FormItem.Label>Slots available</FormItem.Label>
                        <FormItem.Control>
                          <Input {...field} type={"number"} />
                        </FormItem.Control>
                      </FormItem.Row>
                      <FormItem.Message />
                    </FormItem>
                  )}
                />
                <div className="flex flex-col gap-2">
                  {extraOptions.map((option, index) => (
                    <>
                      <Form.Field
                        control={form.control}
                        name={`availability.extraOptions.${index}.extraOptionId`}
                        render={({ field }) => (
                          <FormItem>
                            <FormItem.Row>
                              <FormItem.Control>
                                <Input
                                  name={field.name}
                                  value={option.id}
                                  onChange={field.onChange}
                                  type={"number"}
                                  className="max-w-md hidden"
                                />
                              </FormItem.Control>
                            </FormItem.Row>
                            <FormItem.Message />
                          </FormItem>
                        )}
                      />
                      <Form.Field
                        control={form.control}
                        name={`availability.extraOptions.${index}.available`}
                        key={option.id}
                        render={({ field }) => (
                          <FormItem>
                            <FormItem.Row>
                              <FormItem.Label>{option.name}</FormItem.Label>
                              <FormItem.Control>
                                <Checkbox
                                  checked={field?.value}
                                  onCheckedChange={(checked) => {
                                    field.onChange(checked);
                                  }}
                                />
                              </FormItem.Control>
                            </FormItem.Row>
                            <FormItem.Message />
                          </FormItem>
                        )}
                      />
                    </>
                  ))}
                </div>
                {attractionVariants.length > 0 && (
                  <Button
                    type={"button"}
                    className="w-fit"
                    variant="secondary"
                    onClick={toggleActivationLogsOpened}
                  >
                    {activationLogsOpened ? "Hide" : "Show"} activation logs
                    settings
                  </Button>
                )}

                {activationLogsOpened && (
                  <div className="flex flex-col gap-2">
                    {attractionVariants.map((variant, index) => (
                      <>
                        <Form.Field
                          control={form.control}
                          name={`availability.activationLogs.${index}.variantId`}
                          render={({ field }) => (
                            <FormItem>
                              <FormItem.Control>
                                <Input
                                  name={field.name}
                                  onChange={field.onChange}
                                  type={"number"}
                                  className="hidden"
                                  value={variant.id}
                                />
                              </FormItem.Control>
                              <FormItem.Message />
                            </FormItem>
                          )}
                        />

                        <Form.Field
                          control={form.control}
                          name={`availability.activationLogs.${index}.active`}
                          key={variant.id}
                          render={({ field }) => (
                            <FormItem>
                              <FormItem.Row>
                                <FormItem.Label>
                                  {variant.description}
                                </FormItem.Label>
                                <FormItem.Control>
                                  <Checkbox
                                    checked={field?.value}
                                    onCheckedChange={(checked) => {
                                      field.onChange(checked);
                                    }}
                                  />
                                </FormItem.Control>
                              </FormItem.Row>
                              <FormItem.Message />
                            </FormItem>
                          )}
                        />
                      </>
                    ))}
                  </div>
                )}

                <FormItem.Row>
                  <Form.Field
                    control={form.control}
                    name={`times`}
                    render={({ field }) => (
                      <FormItem>
                        <FormItem.Row>
                          <FormItem.Label>Times</FormItem.Label>
                          <FormItem.Control>
                            <Input {...field} type={"number"} />
                          </FormItem.Control>
                        </FormItem.Row>
                        <FormItem.Message />
                      </FormItem>
                    )}
                  />{" "}
                  <Form.Field
                    control={form.control}
                    name={`days`}
                    render={({ field }) => (
                      <FormItem>
                        <FormItem.Row>
                          <FormItem.Label>Days</FormItem.Label>
                          <FormItem.Control>
                            <Input {...field} type={"number"} />
                          </FormItem.Control>
                        </FormItem.Row>
                        <FormItem.Message />
                      </FormItem>
                    )}
                  />
                </FormItem.Row>
              </Form.Fields>
              <div className={"flex justify-end mt-6 gap-2"}>
                <Button
                  type={"button"}
                  variant="destructive"
                  onClick={onSubmitUnavailable}
                >
                  Not available
                </Button>
                <Button
                  type={"submit"}
                  variant="secondary"
                  disabled={addButtonDisabled}
                >
                  Add
                </Button>
              </div>
            </div>

            <Calendar
              attractionGroups={attractionGroups}
              mutatedAvailabilities={mutatedAvailabilities}
              attractionId={attractionId}
            />
          </div>
        </form>

        <SettingCard.Footer>
          <SettingCard.Description>
            This setting will change variant's description only on the
            dashboard.
          </SettingCard.Description>
          <SettingCard.Button
            onClick={saveMutatedAvailabilities}
            isLoading={isPending}
          >
            Submit
          </SettingCard.Button>
        </SettingCard.Footer>
      </Form>
    </SettingCard>
  );
};
