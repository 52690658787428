import { DateFormatter } from "@twocontinents/shared";

import { BookingOrder } from "../types";

export const useBookingOrderDetails = (order: BookingOrder) => {
  const formattedPurchasedTime = DateFormatter.formatToDDMMYYYYHHmm(
    order.timePurchased,
  );

  const orderDetailsRows = [
    {
      label: "Order ID",
      content: order.id,
    },
    {
      label: "Apartment name",
      content: order.apartmentDescription,
    },
    {
      label: "Apartment internal number",
      content: order.apartmentInternalNumber,
    },
    {
      label: "Purchased time",
      content: formattedPurchasedTime,
    },
    {
      label: "Order status",
      content: order.status,
    },
    {
      label: "Total price",
      content: order.totalPrice.toFixed(2),
    },
    {
      label: "Currency",
      content: order.currency,
    },
    {
      label: "Check-in",
      content: DateFormatter.formatToDDMMYYYYHHmm(order.dateFrom),
    },
    {
      label: "Check-out",
      content: DateFormatter.formatToDDMMYYYYHHmm(order.dateTo),
    },
  ];

  if (order.ageGroupAware) {
    orderDetailsRows.push(
      {
        label: "Adults",
        content: order.adultAmount,
      },
      {
        label: "Children",
        content: order.childAmount,
      },
      {
        label: "Infants",
        content: order.infantAmount,
      },
    );
  } else {
    orderDetailsRows.push({
      label: "Guests",
      content: order.adultAmount,
    });
  }

  if (order.additionalBedsAmount > 0) {
    orderDetailsRows.push({
      label: "Additional guests",
      content: order.additionalBedsAmount,
    });
  }

  order.extraOptions.forEach((extraOption) => {
    orderDetailsRows.push({
      label: extraOption.description,
      content: extraOption.amount,
    });
  });

  return { formattedPurchasedTime, orderDetailsRows };
};
