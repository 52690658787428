import {
  Table,
  buttonVariants,
  cn,
  routes,
} from "@twocontinents/dashboard/shared";
import { Link } from "react-router-dom";

import { AttractionListing } from "../../types";

interface AttractionsTableProps {
  attractions: AttractionListing[];
}

export const AttractionsTable = ({ attractions }: AttractionsTableProps) => {
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.Head className="w-36">ID</Table.Head>
          <Table.Head>Name</Table.Head>
          <Table.Head className="text-center">
            Internal tickets avail.
          </Table.Head>
          <Table.Head>Status</Table.Head>
          <Table.Head className="text-center">Action</Table.Head>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {attractions.length > 0 &&
          attractions.map((attraction) => (
            <Table.Row key={attraction.id}>
              <Table.Cell>{attraction.id}</Table.Cell>
              <Table.Cell>{attraction.description}</Table.Cell>
              <Table.Cell className="text-center">
                {attraction.internalTickets ?? "-"}
              </Table.Cell>
              <Table.Cell>
                <div className="flex items-center">
                  <div
                    className={cn(`mr-2 h-2.5 w-2.5 rounded-full`, {
                      "bg-green-500": attraction.active,
                      "bg-red-500": !attraction.active,
                    })}
                  />
                  {attraction.active ? "Active" : "Inactive"}
                </div>
              </Table.Cell>
              <Table.Cell className="flex flex-col justify-center gap-4">
                <Link
                  to={`${routes.attractions.index.show.url(attraction.id)}`}
                  className={`${buttonVariants({ variant: "outline" })} w-full flex justify-center`}
                >
                  Manage
                </Link>
              </Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  );
};
