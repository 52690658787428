import { Input } from "../../shadcn";
import { useOrderIdFilter } from "./use-order-id-filter";

interface OrderIdFilterProps {
  idIdentifier?: "orderId" | "bookingId";
}

export const OrderIdFilter = ({
  idIdentifier = "orderId",
}: OrderIdFilterProps) => {
  const { id, handleOrderIdChange } = useOrderIdFilter(idIdentifier);

  return (
    <Input
      name="id"
      className="w-44"
      placeholder="Order ID"
      type="number"
      value={id}
      onChange={handleOrderIdChange}
    />
  );
};
