import { ChangeEvent } from "react";

import { useOrderFiltersQueryParams } from "../../../hooks";

export const useCustomerFilter = () => {
  const { query, setQuery } = useOrderFiltersQueryParams();

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) =>
    setQuery({ customerName: event.target.value });

  const handleSurnameChange = (event: ChangeEvent<HTMLInputElement>) =>
    setQuery({ customerSurname: event.target.value });

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) =>
    setQuery({ customerEmail: event.target.value });

  return {
    customerName: query.customerName,
    customerSurname: query.customerSurname,
    customerEmail: query.customerEmail,
    handleNameChange,
    handleSurnameChange,
    handleEmailChange,
  };
};
