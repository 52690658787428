import { Layout, ShopOffering, Tabs } from "@twocontinents/dashboard/shared";

import { useReviewsFiltersQueryParams } from "../hooks/use-reviews-filters-query-params";
import { AttractionReviewsTab } from "./attraction-reviews-tab";
import { BookingReviewsTab } from "./booking-reviews-tab";

export const ReviewsManagement = () => {
  const { query, setQuery } = useReviewsFiltersQueryParams();

  const setTab = (value: ShopOffering) => {
    setQuery({ offerType: value });
  };

  return (
    <Layout
      header={{
        title: "Reviews",
        description: `Manage reviews.`,
      }}
    >
      <Tabs
        className="w-full"
        value={query.offerType}
        defaultValue={ShopOffering.ATTRACTION}
      >
        <Tabs.List>
          <Tabs.Trigger
            value={ShopOffering.ATTRACTION}
            onClick={() => setTab(ShopOffering.ATTRACTION)}
          >
            Attractions
          </Tabs.Trigger>
          <Tabs.Trigger
            value={ShopOffering.STAY}
            onClick={() => setTab(ShopOffering.STAY)}
          >
            Apartments
          </Tabs.Trigger>
        </Tabs.List>
        <Tabs.Content value={ShopOffering.ATTRACTION}>
          <AttractionReviewsTab />
        </Tabs.Content>
        <Tabs.Content value={ShopOffering.STAY}>
          <BookingReviewsTab />
        </Tabs.Content>
      </Tabs>
    </Layout>
  );
};
