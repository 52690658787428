import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";

import { GetBookingReviewsFilters } from "../types";

const queryKey = (filters?: GetBookingReviewsFilters) => [
  "private-booking-reviews",
  filters ?? "all",
];

export const useGetBookingReviews = (filters?: GetBookingReviewsFilters) => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryKey: queryKey(filters),
    queryFn: () =>
      apiClient
        .GET("/private/reviews/booking", {
          params: {
            query: filters,
          },
        })
        .then((response) => response.data),
  });

  return {
    paginatedReviews: data,
    isLoading,
  };
};

export const useInvalidateGetBookingReviews = () => {
  const queryClient = useQueryClient();

  const invalidate = async () => {
    await queryClient.invalidateQueries({
      queryKey: ["private-booking-reviews"],
    });
  };

  return {
    invalidate,
  };
};
