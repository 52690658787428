import { Layout } from "@twocontinents/dashboard/shared";
import { useParams } from "react-router-dom";

import { useGetApartment, useGetApartmentExtraOptions } from "../data-access";
import { ActivitySettings } from "./activity-settings";
import { AdditionalBedsSettings } from "./additional-beds-settings";
import { CalendarSettings } from "./calendar-settings";
import { DefaultPriceSettings } from "./default-price-settings";
import { DefaultStaysAmountSettings } from "./default-stays-amount-settings";
import { DescriptionSettings } from "./description-settings";
import { ExtraOptionsSettings } from "./extra-options-settings";
import { InternalNumberSettings } from "./internal-number-settings";
import { MaxGuestsAmountSettings } from "./max-guests-amount-settings";
import { RevalidateSection } from "./revalidate-section";

export const ApartmentShowPage = () => {
  const params = useParams<{ id: string }>();
  const id = Number(params.id);

  const { apartment, isLoading } = useGetApartment(id);
  const { extraOptions } = useGetApartmentExtraOptions(id);

  if (isLoading || !apartment) {
    return (
      <Layout
        header={{
          title: `Manage Apartment ...`,
          description: "Browse and manage apartments",
        }}
      ></Layout>
    );
  }

  return (
    <Layout
      header={{
        title: `Manage Apartment: ${apartment.id}. ${apartment.description} (${apartment.internalNumber})`,
        description: "Browse and manage apartment",
      }}
    >
      <Layout.Content>
        <RevalidateSection apartmentId={apartment.id} />
        <ActivitySettings
          apartmentId={apartment.id}
          defaultActive={apartment.active}
        />
        <DescriptionSettings
          apartmentId={apartment.id}
          defaultDescription={apartment.description}
        />
        <InternalNumberSettings
          apartmentId={apartment.id}
          defaultInternalNumber={apartment.internalNumber}
        />
        <AdditionalBedsSettings
          maxAdditionalBedsAmount={apartment.maxAdditionalBedsAmount}
          currentAdditionalBedPrice={apartment.additionalBedPrice}
          apartmentId={apartment.id}
        />
        {apartment.managedInternally && (
          <>
            <DefaultStaysAmountSettings
              apartmentId={apartment.id}
              currentMinimumStay={apartment.defaultMinimumStay}
              currentMaximumStay={apartment.defaultMaximumStay}
            />
            <MaxGuestsAmountSettings
              apartmentId={apartment.id}
              currentMaxGuestsAmount={apartment.maxGuestsAmount}
              currentMaxAdultsAmount={apartment.maxAdultsAmount}
            />
            <DefaultPriceSettings
              apartmentId={apartment.id}
              currentDefaultPrice={apartment.defaultPrice}
            />
            <CalendarSettings apartmentId={apartment.id} />
          </>
        )}

        {extraOptions.length > 0 && (
          <ExtraOptionsSettings extraOptions={extraOptions} apartmentId={id} />
        )}
      </Layout.Content>
    </Layout>
  );
};
