import { zodResolver } from "@hookform/resolvers/zod";
import {
  FinancialLogCategory,
  useOpened,
} from "@twocontinents/dashboard/shared";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useUpdateFinancialLog } from "../../data-access";
import { FinancialLog } from "../../types";

const createFormSchema = (minValue: number, maxValue: number) => {
  return z.object({
    value: z.coerce
      .number()
      .min(minValue, { message: `Min value is ${minValue}` })
      .max(maxValue, { message: `Max value is ${maxValue}` }),
    cause: z.string().min(3),
    notes: z.string().min(3).optional(),
    category: z.nativeEnum(FinancialLogCategory),
  });
};

type CreateCorrectionLogFormSchema = z.infer<
  ReturnType<typeof createFormSchema>
>;

export const useCreateCorrectionFinancialLogModal = (
  financialLog: FinancialLog,
) => {
  const { opened, open, close } = useOpened(false);
  const { updateFinancialLog, isPending } = useUpdateFinancialLog();

  const minValue = financialLog.finalValue > 0 ? 0 : Number.NEGATIVE_INFINITY;
  const maxValue = financialLog.finalValue < 0 ? 0 : Number.POSITIVE_INFINITY;

  const form = useForm<CreateCorrectionLogFormSchema>({
    resolver: zodResolver(createFormSchema(minValue, maxValue)),
    mode: "onBlur",
    defaultValues: {
      value: financialLog.finalValue,
      cause: financialLog.settled ? undefined : financialLog.cause,
      notes: financialLog.settled ? undefined : financialLog.notes,
      category: financialLog.category,
    },
  });

  const onSubmit = form.handleSubmit((data) => {
    updateFinancialLog({
      id: financialLog.id,
      body: data,
    });
    form.reset({
      value: data.value,
      cause: data.cause,
      notes: data.notes,
    });
    close();
  });

  return {
    form,
    onSubmit,
    opened,
    open,
    close,
    isPending,
    minValue,
    maxValue,
  };
};
