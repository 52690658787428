import { zodResolver } from "@hookform/resolvers/zod";
import {
  MultiSelectOptionGroup,
  MultiSelectOptionType,
  useGetApartments,
  useGetAttractions,
  useGetAttractionsDetails,
} from "@twocontinents/dashboard/shared";
import { CurrencyCode, DateFormatter } from "@twocontinents/shared";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useCreateCoupon } from "../../data-access";
import { useCouponFiltersQueryParams } from "../../hooks";

const AddCouponFormSchema = z
  .object({
    code: z
      .string({
        required_error: "Code is required",
        invalid_type_error: "Code is required",
      })
      .min(3, "Code should contain at least 3 characters"),
    description: z
      .string({
        required_error: "Description is required",
        invalid_type_error: "Description is required",
      })
      .min(3, "Description should contain at least 3 characters"),
    value: z.coerce
      .number({
        required_error: "Coupon value is required",
        invalid_type_error: "Coupon value is required",
      })
      .positive("Coupon value should be positive"),
    currency: z.nativeEnum(CurrencyCode),
    availableUsages: z.coerce.number().optional(),
    startDate: z.string().optional(),
    startTime: z.string().optional(),
    endDate: z.string().optional(),
    endTime: z.string().optional(),
    reservationPeriodStartDate: z.string().optional(),
    reservationPeriodStartTime: z.string().optional(),
    reservationPeriodEndDate: z.string().optional(),
    reservationPeriodEndTime: z.string().optional(),
    attractionVariantIds: z.coerce.number().array(),
    apartmentIds: z.coerce.number().array(),
    minimumStays: z.coerce.number().optional(),
    infinityUsages: z.boolean(),
  })
  .superRefine((data, ctx) => {
    if (!data.startDate || !data.endDate || !data.startTime || !data.endTime)
      return;
    const startDateTime = new Date(`${data.startDate} ${data.startTime}`);
    const endDateTime = new Date(`${data.endDate} ${data.endTime}`);

    if (startDateTime >= endDateTime) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Start date cannot be later or equal to end date",
        path: ["startDateTime"],
      });
    }
  })
  .superRefine((data, ctx) => {
    if (
      !data.reservationPeriodStartDate ||
      !data.reservationPeriodEndDate ||
      !data.reservationPeriodStartTime ||
      !data.reservationPeriodEndTime
    )
      return;
    const reservationPeriodStartDateTime = new Date(
      `${data.reservationPeriodStartDate} ${data.reservationPeriodStartTime}`,
    );
    const reservationPeriodEndDateTime = new Date(
      `${data.reservationPeriodEndDate} ${data.reservationPeriodEndTime}`,
    );

    if (reservationPeriodStartDateTime >= reservationPeriodEndDateTime) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          "Data pierwszego dnia akceptowanych rezerwacji nie może być późniejsza lub równa dacie ostatniego dnia akceptowanych rezerwacji",
        path: ["reservationPeriodStartDateTime"],
      });
    }
  })
  .superRefine((data, ctx) => {
    if (!data.infinityUsages && !data.availableUsages) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Ilość użyć nie może być równa 0",
        path: ["availableUsages"],
      });
    }
    if (data.apartmentIds.length > 0 && !data.minimumStays) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Minimum stays is required",
        path: ["minimumStays"],
      });
    }
  });

type AddCouponFormSchema = z.infer<typeof AddCouponFormSchema>;

export const useAddCouponForm = (setShowForm: (showForm: boolean) => void) => {
  const { query } = useCouponFiltersQueryParams();
  const [selectedVariants, setSelectedVariants] = useState<
    MultiSelectOptionType[]
  >([]);

  const offerType = query.offerType;

  const closeForm = () => {
    setShowForm(false);
  };

  const { attractions: attractionsBase } = useGetAttractions();

  const { attractions, isLoading } = useGetAttractionsDetails(
    attractionsBase.map((attraction) => attraction.id),
  );

  const variantsOptions: MultiSelectOptionGroup[] = useMemo(() => {
    return (
      attractions?.map((attraction) => {
        return {
          label: attraction?.description ?? "",
          options:
            attraction?.attractionGroups.flatMap((group) =>
              group.attractionVariants
                .filter((variant) => variant.active)
                .map((variant) => {
                  return {
                    label: variant.description,
                    value: variant.id,
                  };
                }),
            ) ?? [],
        };
      }) ?? []
    );
  }, [attractions]);

  const { apartments } = useGetApartments();

  const apartmentsOptions: MultiSelectOptionGroup[] = [
    {
      label: "Apartments",
      options: apartments.map((apartment) => ({
        label: apartment.description,
        value: apartment.id,
      })),
    },
  ];

  const { createCoupon, isCreatingCoupon } = useCreateCoupon();

  const form = useForm<AddCouponFormSchema>({
    mode: "onBlur",
    resolver: zodResolver(AddCouponFormSchema),
    defaultValues: {
      infinityUsages: false,
      availableUsages: 1,
      attractionVariantIds: [],
      apartmentIds: [],
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = form;

  const selectedApartmentIds = watch("apartmentIds");
  const infinityUsages = watch("infinityUsages");

  const handleApartmentsChange = (value: MultiSelectOptionType[]) => {
    setValue(
      "apartmentIds",
      value.map((option) => option.value),
    );
  };

  const onSubmit = (formData: AddCouponFormSchema) => {
    const {
      reservationPeriodEndDate,
      reservationPeriodStartDate,
      reservationPeriodEndTime,
      reservationPeriodStartTime,
      startTime,
      startDate,
      endTime,
      endDate,
      ...data
    } = formData;
    createCoupon(
      {
        ...data,
        shopOffering: offerType,
        startDateTime:
          startDate && startTime
            ? DateFormatter.formatToZonedDateTime(`${startDate} ${startTime}`)
            : undefined,
        endDateTime:
          endDate && endTime
            ? DateFormatter.formatToZonedDateTime(`${endDate} ${endTime}`)
            : undefined,
        reservationPeriodStartDateTime:
          reservationPeriodStartDate && reservationPeriodStartTime
            ? DateFormatter.formatToZonedDateTime(
                `${reservationPeriodStartDate} ${reservationPeriodStartTime}`,
              )
            : undefined,
        reservationPeriodEndDateTime:
          reservationPeriodEndDate && reservationPeriodEndTime
            ? DateFormatter.formatToZonedDateTime(
                `${reservationPeriodEndDate} ${reservationPeriodEndTime}`,
              )
            : undefined,
      },
      { onSuccess: () => closeForm() },
    );
  };

  useEffect(() => {
    setValue(
      "attractionVariantIds",
      selectedVariants.map((option) => option.value),
    );
  }, [selectedVariants, setValue]);

  useEffect(() => {
    setSelectedVariants([]);
    handleApartmentsChange([]);
    setValue("minimumStays", undefined);
  }, [offerType]);

  const selectedApartments = apartmentsOptions[0].options.filter((option) =>
    selectedApartmentIds.includes(option.value),
  );

  const values = form.watch();

  return {
    form,
    register,
    handleSubmit,
    errors,
    variantsOptions,
    selectedVariants,
    setSelectedVariants,
    isLoading,
    onSubmit,
    isCreatingCoupon,
    infinityUsages,
    setValue,
    closeForm,
    apartmentsOptions,
    selectedApartments,
    handleApartmentsChange,
    offerType,
    values,
  };
};
