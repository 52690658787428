import { useState } from "react";

import { useGetAttractionCoupons } from "../data-access";
import { useCouponFiltersQueryParams } from "../hooks";

export const useAttractionCouponsTab = () => {
  const { query } = useCouponFiltersQueryParams();
  const { coupons, pagination, arePaginatedCouponsLoading } =
    useGetAttractionCoupons({
      page: query.page,
      size: query.size,
    });

  return {
    coupons,
    pagination,
    arePaginatedCouponsLoading,
  };
};
