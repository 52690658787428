import { useFormContext } from "react-hook-form";

import { PaymentStatus } from "../../../types";
import { Form, FormItem, Switch } from "../../shadcn";
import { ConfirmPaymentAlertDialog } from "./confirm-payment-alert-dialog";
import { OrderStatusesFormValues } from "./use-order-statuses-form";

interface PaymentStatusSwitch {
  orderId: number;
  status?: PaymentStatus;
}

export const PaymentStatusSwitch = ({
  orderId,
  status,
}: PaymentStatusSwitch) => {
  const { control, watch } = useFormContext<OrderStatusesFormValues>();

  const paymentConfirmed = watch("paymentConfirmed");

  return (
    <Form.Field
      control={control}
      name="status"
      render={({ field }) => (
        <FormItem className="flex items-center flex-col gap-2">
          {paymentConfirmed ? (
            <h3 className="text-sm">
              Payment status {status ? `(${status})` : ""}
            </h3>
          ) : (
            <ConfirmPaymentAlertDialog status={status} orderId={orderId} />
          )}
          <div className="flex items-center justify-start">
            <FormItem.Control>
              <Switch
                id={field.name}
                disabled
                checked={paymentConfirmed}
                onCheckedChange={field.onChange}
              />
            </FormItem.Control>
            <FormItem.Label
              className={`ml-3 ${paymentConfirmed ? "text-green-600" : "text-red-600"}`}
            >
              {paymentConfirmed ? "Paid" : "Unpaid"}
            </FormItem.Label>
          </div>
        </FormItem>
      )}
    />
  );
};
