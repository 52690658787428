import { zodResolver } from "@hookform/resolvers/zod";
import {
  NOT_SELECTED_ID,
  ShopOffering,
  toast,
  useGetApartments,
  useGetAttractions,
  useOpened,
} from "@twocontinents/dashboard/shared";
import {
  ApiLocale,
  DateFormatter,
  MAX_FILE_SIZE_IN_BYTES,
  MAX_FILE_SIZE_IN_MB,
  convertHeicToJpeg,
  isHeic,
} from "@twocontinents/shared";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useCreateManualReview } from "../../data-access";
import { useReviewsFiltersQueryParams } from "../../hooks/use-reviews-filters-query-params";

const AddManualReviewFormSchema = z.object({
  rating: z.coerce.number().step(0.5).min(1).max(5),
  content: z.string().min(3).max(500).optional(),
  productId: z.coerce.number(),
  customerName: z.string(),
  createdAtDate: z.string(),
  createdAtTime: z.string(),
  locale: z.nativeEnum(ApiLocale),
});

type AddManualReviewFormValues = z.infer<typeof AddManualReviewFormSchema>;

export const useAddManualReviewDialog = () => {
  const { opened, close, toggle } = useOpened(false);
  const { createManualReview, isPending } = useCreateManualReview();
  const [pictures, setPictures] = useState<File[]>([]);
  const [isConverting, setIsConverting] = useState<boolean>(false);

  const { query } = useReviewsFiltersQueryParams();
  const offerType = query.offerType;

  const form = useForm<AddManualReviewFormValues>({
    resolver: zodResolver(AddManualReviewFormSchema),
  });

  const productId = form.watch("productId");

  useEffect(() => {
    form.setValue("productId", NOT_SELECTED_ID);
  }, [offerType, form]);

  const createdAtTime = form.watch("createdAtTime");

  const { attractions } = useGetAttractions();
  const { apartments } = useGetApartments();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileInputChange = async () => {
    const files = fileInputRef.current?.files;

    if (!files) return;

    if (files.length > 5) {
      toast.error("Maximum of 5 images allowed per review");
      return;
    }

    const processedFiles: File[] = [];

    for (const file of files) {
      let image: File = file;
      try {
        if (isHeic(file)) {
          setIsConverting(true);
          image = await convertHeicToJpeg(file);
          setIsConverting(false);
        }

        if (file.size > MAX_FILE_SIZE_IN_BYTES) {
          toast.error(
            `Image "${file.name}" exceeds the maximum file size of ${MAX_FILE_SIZE_IN_MB}MB`,
          );
          continue;
        }
        processedFiles.push(image);
      } catch {
        toast.error(
          `Failed to process image "${file.name}". Please try again with a different file`,
        );
      }
    }

    setPictures(processedFiles);
  };

  const onSubmit = form.handleSubmit((data) => {
    const { createdAtDate, createdAtTime, ...rest } = data;
    createManualReview(
      {
        pictures,
        body: {
          ...rest,
          shopOffering: offerType,
          createdAt: DateFormatter.formatToDDMMTYYYYTHHmm(
            new Date(`${createdAtDate} ${createdAtTime}`),
          ),
        },
      },
      {
        onSuccess: () => {
          form.reset();
          setPictures([]);
          close();
        },
      },
    );
  });

  const isFormValid = form.formState.isValid && productId !== NOT_SELECTED_ID;

  return {
    opened,
    form,
    onSubmit,
    isPending,
    pictures,
    attractions,
    createdAtTime,
    handleFileInputChange,
    fileInputRef,
    toggle,
    isConverting,
    offerType,
    apartments,
    isFormValid,
  };
};
