import { ShopOffering } from "@twocontinents/dashboard/shared";
import {
  NumberParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

export const useCouponFiltersQueryParams = () => {
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 0),
    size: withDefault(NumberParam, 5),
    offerType: withDefault(
      createEnumParam(Object.values(ShopOffering)),
      ShopOffering.ATTRACTION,
    ),
  });

  return {
    query,
    setQuery,
  };
};
