import { zodResolver } from "@hookform/resolvers/zod";
import { CurrencyCode, Money } from "@twocontinents/shared";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useUpdateApartment } from "../../data-access";

const AdditionalBedsSettingsSchema = z.object({
  additionalBedPrice: z.object({
    amount: z.coerce.number().min(0),
    currency: z.nativeEnum(CurrencyCode),
  }),
  maxAdditionalBedsAmount: z.coerce.number().min(0),
});

type AdditionalBedsSettingsSchema = z.infer<
  typeof AdditionalBedsSettingsSchema
>;

export const useAdditionalBedsSettings = (
  apartmentId: number,
  maxAdditionalBedsAmount: number,
  currentAdditionalBedPrice: Money,
) => {
  const { updateApartment, isPending } = useUpdateApartment();

  const form = useForm<AdditionalBedsSettingsSchema>({
    resolver: zodResolver(AdditionalBedsSettingsSchema),
    defaultValues: {
      additionalBedPrice: currentAdditionalBedPrice,
      maxAdditionalBedsAmount: maxAdditionalBedsAmount,
    },
  });

  const { handleSubmit } = form;

  const onSubmit = handleSubmit((body) => {
    updateApartment({
      apartmentId,
      body,
    });
  });

  return {
    form,
    onSubmit,
    isPending,
  };
};
