import {
  StatusIndicator,
  Table,
  useTableDropdown,
} from "@twocontinents/dashboard/shared";
import { DateFormatter } from "@twocontinents/shared";

import { AttractionReview, BookingReview } from "../../types";
import { ModerateReviewAlertDialog } from "./moderate-review-alert-dialog";
import { ReviewContentDropdown } from "./review-content-dropdown";

const isAttractionTypeReview = (
  review: AttractionReview | BookingReview,
): review is AttractionReview => {
  return (review as AttractionReview).attractionName !== undefined;
};

interface ReviewTableProps {
  reviews: (AttractionReview | BookingReview)[];
}

export const ReviewsTable = ({ reviews }: ReviewTableProps) => {
  const { areDropdownsOpened, handleDropdownOpenChange, openDropdown } =
    useTableDropdown();

  const isAttractionReviewsTable = reviews.every((review) =>
    isAttractionTypeReview(review),
  );

  return (
    <Table className="w-full  bg-slate-950 ">
      <Table.Header className="w-full rounded-3xl ">
        <Table.Row className="border-slate-700">
          <Table.Head className="py-5">ID</Table.Head>
          <Table.Head className="py-5">
            {isAttractionReviewsTable ? "Attraction" : "Apartment"}
          </Table.Head>
          <Table.Head className="py-5">Rating</Table.Head>
          <Table.Head className="py-5 max-w-xs">Email</Table.Head>
          <Table.Head className="py-5">Full name</Table.Head>
          <Table.Head className="py-5">
            {isAttractionReviewsTable ? "Reservation Date" : "Date range"}
          </Table.Head>
          <Table.Head className="py-5">Review date</Table.Head>
          <Table.Head className="py-5">Visibity</Table.Head>
          <Table.Head className="py-5">Locale</Table.Head>
          <Table.Head className="py-5">Action</Table.Head>
        </Table.Row>
      </Table.Header>
      <Table.Body className="w-full relative">
        {reviews.map((review, i) => {
          const isAttractionReview = isAttractionTypeReview(review);

          const reservationDate =
            isAttractionReview && review.reservationDate
              ? DateFormatter.formatToDDMMYYYY(review.reservationDate)
              : "N/A";

          const bookingDateRange =
            !isAttractionReview &&
            review.bookingDateFrom &&
            review.bookingDateTo
              ? `${DateFormatter.formatToDDMMYYYY(review.bookingDateFrom)} - ${DateFormatter.formatToDDMMYYYY(review.bookingDateTo)}`
              : "N/A";

          return (
            <Table.Row key={review.id} onClick={() => openDropdown(i)}>
              <Table.Cell>
                <ReviewContentDropdown
                  review={review}
                  setIsDropdownOpened={(opened: boolean) =>
                    handleDropdownOpenChange(opened, i)
                  }
                  isDropdownOpened={areDropdownsOpened[i]}
                />
              </Table.Cell>
              <Table.Cell>
                {isAttractionReview
                  ? review.attractionName
                  : review.apartmentName}
              </Table.Cell>
              <Table.Cell>{review.rating.toFixed(1)} / 5.0</Table.Cell>
              <Table.Cell>{review.customerEmail ?? "N/A"}</Table.Cell>
              <Table.Cell>
                {review.customerName} {review.customerSurname}
              </Table.Cell>
              <Table.Cell>
                {isAttractionReview ? reservationDate : bookingDateRange}
              </Table.Cell>
              <Table.Cell>
                {DateFormatter.formatToDDMMYYYY(review.createdAt)}
              </Table.Cell>
              <Table.Cell>
                <StatusIndicator
                  status={review.hidden ? "negative" : "positive"}
                >
                  {review.hidden ? "Hidden" : "Visible"}
                </StatusIndicator>
              </Table.Cell>
              <Table.Cell>{review.locale}</Table.Cell>
              <Table.Cell onClick={(e) => e.stopPropagation()}>
                <ModerateReviewAlertDialog review={review} />
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};
