import { ApiLocale, SortDirection } from "@twocontinents/shared";
import {
  BooleanParam,
  NumberParam,
  StringParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import { ALL_FILTER_VALUE } from "../constants";
import { OrderStatus } from "../types";
import { usePaginationQueryParams } from "./use-pagination-query-params";

export const useOrderFiltersQueryParams = () => {
  const [query, setQuery] = useQueryParams({
    orderId: withDefault(NumberParam, undefined),
    status: withDefault(
      createEnumParam([ALL_FILTER_VALUE, ...Object.values(OrderStatus)]),
      OrderStatus.NEW,
    ),
    sortBy: withDefault(StringParam, "timePurchased"),
    sortDirection: withDefault(
      createEnumParam(Object.values(SortDirection)),
      SortDirection.DESC,
    ),
    customerName: withDefault(StringParam, undefined),
    customerSurname: withDefault(StringParam, undefined),
    customerEmail: withDefault(StringParam, undefined),
    reservationDate: withDefault(StringParam, undefined),
    reservationDateFrom: withDefault(StringParam, undefined),
    reservationDateTo: withDefault(StringParam, undefined),
    locale: withDefault(createEnumParam(Object.values(ApiLocale)), undefined),
    attractionId: withDefault(NumberParam, undefined),
    paymentConfirmed: withDefault(BooleanParam, undefined),
  });

  const { page, size } = usePaginationQueryParams();

  return {
    query: {
      ...query,
      page,
      size,
    },
    setQuery,
  };
};
