import {
  ALL_FILTER_VALUE,
  AllFilterValue,
  Layout,
  OrderStatus,
  Tabs,
  useOrderFiltersQueryParams,
} from "@twocontinents/dashboard/shared";

import { OrdersTab } from "./orders-tab";

export const OrdersPage = () => {
  const { query, setQuery } = useOrderFiltersQueryParams();

  function setStatus(status: OrderStatus | AllFilterValue) {
    return () => {
      setQuery({ status });
    };
  }

  return (
    <Layout
      header={{
        title: "Orders",
        description: `Manage your orders.`,
      }}
    >
      <Tabs className="w-full" value={query.status}>
        <Tabs.List className="mt-5 flex flex-col gap-y-2 min-[830px]:grid  w-full h-min min-[830px]:h-14  min-[830px]:grid-cols-3  p-2 ">
          <Tabs.Trigger
            className="h-full w-full min-[830px]:w-auto  py-5 min-[830px]:py-0 "
            value={OrderStatus.NEW}
            onClick={setStatus(OrderStatus.NEW)}
          >
            New
          </Tabs.Trigger>
          <Tabs.Trigger
            className="h-full w-full min-[830px]:w-auto py-5 min-[830px]:py-0 "
            value={OrderStatus.COMPLETED}
            onClick={setStatus(OrderStatus.COMPLETED)}
          >
            Completed
          </Tabs.Trigger>
          <Tabs.Trigger
            className="h-full w-full min-[830px]:w-auto py-5 min-[830px]:py-0 "
            value={ALL_FILTER_VALUE}
            onClick={setStatus(ALL_FILTER_VALUE)}
          >
            All
          </Tabs.Trigger>
        </Tabs.List>

        <Tabs.Content value={OrderStatus.NEW}>
          <OrdersTab />
        </Tabs.Content>
        <Tabs.Content value={OrderStatus.COMPLETED}>
          <OrdersTab />
        </Tabs.Content>
        <Tabs.Content value={ALL_FILTER_VALUE}>
          <OrdersTab />
        </Tabs.Content>
      </Tabs>
    </Layout>
  );
};
