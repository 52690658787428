import {
  useInvalidateGetApartmentCoupons,
  useInvalidateGetAttractionCoupons,
} from "../data-access";

export const useInvalidateGetCoupons = () => {
  const { invalidate: invalidateAttractions } =
    useInvalidateGetAttractionCoupons();
  const { invalidate: invalidateApartments } =
    useInvalidateGetApartmentCoupons();

  const invalidate = async () => {
    await invalidateAttractions();
    await invalidateApartments();
  };

  return {
    invalidate,
  };
};
