import { ChangeEvent } from "react";
import { useQueryParams } from "use-query-params";

export const useOrderIdFilter = (idIdentifier: "orderId" | "bookingId") => {
  const [query, setQuery] = useQueryParams();

  const handleOrderIdChange = (event: ChangeEvent<HTMLInputElement>) =>
    setQuery({ [idIdentifier]: event.target.valueAsNumber || undefined });

  return {
    id: query[idIdentifier],
    handleOrderIdChange,
  };
};
