import {
  Button,
  Checkbox,
  StatusIndicator,
  Table,
  routes,
} from "@twocontinents/dashboard/shared";
import { DateFormatter } from "@twocontinents/shared";
import { AlertTriangle } from "lucide-react";
import { Link } from "react-router-dom";

import { BookingOrderBase } from "../types";

interface BookingOrderRowProps {
  order: BookingOrderBase;
  onRowCheckedChange: (checked: boolean, id: number) => void;
  isRowChecked: boolean;
}

export const BookingTableRow = ({
  order,
  onRowCheckedChange,
  isRowChecked,
}: BookingOrderRowProps) => {
  const formattedTimePurchased = DateFormatter.formatToDDMMYYYYHHmm(
    order.timePurchased,
  );
  const dateFrom = DateFormatter.formatToDDMMYYYY(order.dateFrom);
  const dateTo = DateFormatter.formatToDDMMYYYY(order.dateTo);

  const hasAdditionalDetails =
    order.orderAdditionalDetails && order.orderAdditionalDetails.trim() !== "";

  const paymentStatusLabel = order.paymentConfirmed ? "Paid" : "Unpaid";
  const paymentStatusColorVariant = order.paymentConfirmed
    ? "positive"
    : "negative";

  return (
    <Table.Row key={order.id} className=" border-slate-700 hover:bg-slate-900">
      <Table.Cell>
        <Checkbox
          id={`select-${order.id}`}
          className="data-[state=checked]:bg-slate-200 data-[state=checked]:text-slate-800 w-6 h-6 border-slate-200"
          checked={isRowChecked}
          onCheckedChange={(checked: boolean) => {
            onRowCheckedChange(checked, order.id);
          }}
        />
      </Table.Cell>
      <Table.Cell>{order.id}</Table.Cell>
      <Table.Cell>
        {order.apartmentDescription} ({order.apartmentInternalNumber})
      </Table.Cell>
      <Table.Cell>{formattedTimePurchased} </Table.Cell>
      <Table.Cell>{dateFrom}</Table.Cell>
      <Table.Cell>{dateTo}</Table.Cell>
      <Table.Cell>{order.customerFullName}</Table.Cell>
      <Table.Cell>{order.locale}</Table.Cell>
      <Table.Cell>
        <Link
          onClick={(event) => {
            event.stopPropagation();
          }}
          to={routes.stays.orders.show.url(order.id)}
        >
          <Button variant="outline" className="relative">
            Open
            {hasAdditionalDetails && (
              <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2">
                <AlertTriangle size={20} color="#002aff" />
              </div>
            )}
          </Button>
        </Link>
      </Table.Cell>{" "}
      <Table.Cell>
        <StatusIndicator status={paymentStatusColorVariant}>
          {paymentStatusLabel}
        </StatusIndicator>
      </Table.Cell>
    </Table.Row>
  );
};
