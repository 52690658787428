import { zodResolver } from "@hookform/resolvers/zod";
import { CurrencyCode, Money } from "@twocontinents/shared";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useUpdateApartment } from "../../data-access";

const DefaultPriceSettingsSchema = z.object({
  defaultPrice: z.object({
    amount: z.coerce.number().min(0),
    currency: z.nativeEnum(CurrencyCode),
  }),
});

type DefaultPriceSettingsSchema = z.infer<typeof DefaultPriceSettingsSchema>;

export const useDefaultPriceSettings = (
  apartmentId: number,
  currentDefaultPrice: Money,
) => {
  const { updateApartment, isPending } = useUpdateApartment();

  const form = useForm<DefaultPriceSettingsSchema>({
    resolver: zodResolver(DefaultPriceSettingsSchema),
    defaultValues: {
      defaultPrice: currentDefaultPrice,
    },
  });

  const { handleSubmit } = form;

  const onSubmit = handleSubmit((body) => {
    updateApartment({
      apartmentId,
      body,
    });
  });

  return {
    form,
    onSubmit,
    isPending,
  };
};
