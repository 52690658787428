import { CouponsTab } from "./coupons-tab";
import { useAttractionCouponsTab } from "./use-attraction-coupons-tab";

export const AttractionCouponsTab = () => {
  const { arePaginatedCouponsLoading, coupons, pagination } =
    useAttractionCouponsTab();

  return (
    <CouponsTab
      coupons={coupons}
      totalPages={pagination?.totalPages ?? 0}
      arePaginatedCouponsLoading={arePaginatedCouponsLoading}
    />
  );
};
