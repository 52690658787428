import { DateFormatter } from "@twocontinents/shared";

import { DatePicker } from "../date-picker";
import { DateRangePicker } from "../date-range-picker";
import { DateTimePicker } from "../date-time-picker";
import { Label, TimePicker } from "../shadcn";

interface SingleOrRangeDatePickerProps {
  showRangePicker: boolean;
  toggleRangePicker: () => void;
  dateFrom: string | undefined;
  setDateFrom: (date: Date | undefined) => void;
  dateTo: string | undefined;
  setDateTo: (date: Date | undefined) => void;
  time: string | undefined;
  setTime?: (time: string | undefined) => void;
  date: string | undefined;
  setDate: (date: Date | undefined) => void;
}

export const SingleOrRangeDatePicker = ({
  showRangePicker,
  toggleRangePicker,
  dateTo,
  setDate,
  setTime,
  setDateFrom,
  setDateTo,
  time,
  dateFrom,
  date,
}: SingleOrRangeDatePickerProps) => {
  return showRangePicker ? (
    <div className="flex gap-2">
      <div className="flex flex-col gap-2">
        <Label className="inline-flex justify-between gap-2">
          <button
            onClick={toggleRangePicker}
            className="text-slate-400 text-xs"
          >
            Single date
          </button>
          Date range
        </Label>
        <DateRangePicker
          dateFrom={
            dateFrom ? DateFormatter.formatToNativeDate(dateFrom) : undefined
          }
          setDateFrom={setDateFrom}
          dateTo={dateTo ? DateFormatter.formatToNativeDate(dateTo) : undefined}
          setDateTo={setDateTo}
        />
      </div>
      {setTime && <TimePicker value={time} onChange={setTime} />}
    </div>
  ) : (
    <div className="gap-2 flex flex-col">
      <div className="inline-flex justify-between gap-2">
        Single date
        <button onClick={toggleRangePicker} className="text-slate-400 text-xs">
          Date range
        </button>
      </div>
      {setTime ? (
        <DateTimePicker
          date={date ? DateFormatter.formatToNativeDate(date) : undefined}
          setDate={setDate}
          time={time}
          setTime={setTime}
        />
      ) : (
        <DatePicker
          date={date ? DateFormatter.formatToNativeDate(date) : undefined}
          setDate={setDate}
        />
      )}
    </div>
  );
};
