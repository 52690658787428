import { Tour } from "@twocontinents/dashboard/attractions/shared";
import {
  Checkbox,
  Table,
  buttonVariants,
  cn,
  routes,
} from "@twocontinents/dashboard/shared";
import { TravelAgencyIcon } from "@twocontinents/shared-ui";
import { Link } from "react-router-dom";

import { DeleteTourParticipantsDialog } from "../delete-tour-participants-dialog";
import { EditTourParticipantDialog } from "../edit-tour-participant-dialog";
import { MoveTourParticipantsDialog } from "../move-tour-participants-dialog";
import {
  hasBeenBoughtByAgent,
  useTourParticipantsTable,
} from "./use-tour-participants-table";

interface TourParticipantsTableProps {
  tour: Tour;
}

export const TourParticipantsTable = ({ tour }: TourParticipantsTableProps) => {
  const {
    setSelectedParticipants,
    allParticipantsSelected,
    selectedParticipants,
    sortedParticipants,
  } = useTourParticipantsTable(tour);

  return (
    <>
      {selectedParticipants.length > 0 && (
        <div className="flex gap-4">
          <MoveTourParticipantsDialog
            participantIds={selectedParticipants}
            tour={tour}
          />
          <DeleteTourParticipantsDialog
            participantIds={selectedParticipants}
            tour={tour}
          />
        </div>
      )}
      <Table className="w-full  bg-slate-950 ">
        <Table.Header className="w-full rounded-3xl ">
          <Table.Row className="border-slate-700">
            <Table.Head className="w-[40px] px-4">
              <Checkbox
                id="select-all"
                className="data-[state=checked]:bg-slate-200 data-[state=checked]:text-slate-800 w-6 h-6"
                checked={allParticipantsSelected}
                onCheckedChange={(e) => {
                  if (e.valueOf()) {
                    setSelectedParticipants(
                      sortedParticipants.map((participant) => participant.id) ??
                        [],
                    );
                  } else {
                    setSelectedParticipants([]);
                  }
                }}
              />
            </Table.Head>
            <Table.Head className="py-5">Full Name</Table.Head>
            <Table.Head className="py-5">Locale</Table.Head>
            <Table.Head className="py-5">Gender</Table.Head>
            <Table.Head className="py-5">Age group</Table.Head>
            <Table.Head className="py-5 w-1/4">Pickup Location</Table.Head>
            <Table.Head className="py-5 w-1/4">Phone number</Table.Head>
            <Table.Head className="py-5">Order ID</Table.Head>
            <Table.Head className="py-5 w-1/4">Extra options</Table.Head>
            <Table.Head className="py-5 w-1/4">Additions</Table.Head>
            <Table.Head className="py-5">Action</Table.Head>
          </Table.Row>
        </Table.Header>
        <Table.Body className="w-full relative">
          {sortedParticipants.map((participant) => (
            <Table.Row key={participant.id}>
              <Table.Cell className={"relative"}>
                {hasBeenBoughtByAgent(participant) && (
                  <div className="absolute top-0.5 left-0.5">
                    <TravelAgencyIcon />
                  </div>
                )}

                <Checkbox
                  id={`select-${participant.id}`}
                  className="data-[state=checked]:bg-slate-200 data-[state=checked]:text-slate-800 w-6 h-6"
                  checked={selectedParticipants.includes(participant.id)}
                  onCheckedChange={(e) => {
                    if (e.valueOf()) {
                      setSelectedParticipants((prev) => [
                        ...prev,
                        participant.id,
                      ]);
                    } else {
                      setSelectedParticipants((prev) =>
                        prev.filter((id) => id !== participant.id),
                      );
                    }
                  }}
                />
              </Table.Cell>
              <Table.Cell>
                {participant.name} {participant.surname}
              </Table.Cell>
              <Table.Cell>{participant.locale}</Table.Cell>
              <Table.Cell>{participant.gender}</Table.Cell>
              <Table.Cell>{participant.ageGroup.description}</Table.Cell>
              <Table.Cell>{participant.pickupLocation}</Table.Cell>
              <Table.Cell>{participant.phoneNumber}</Table.Cell>

              <Table.Cell>
                {participant.orderId ? (
                  <Link
                    className={cn(
                      "p-6 !text-slate-100 !font-bold",
                      buttonVariants({ variant: "link" }),
                    )}
                    to={routes.attractions.orders.show.url(participant.orderId)}
                  >
                    {participant.orderId}
                  </Link>
                ) : (
                  "Added manually"
                )}
              </Table.Cell>
              <Table.Cell>
                {participant.extraOptions.map(({ amount, extraOption }) => (
                  <p key={`${participant.id} ${extraOption.id}`}>
                    {amount} x {extraOption.name}
                  </p>
                ))}
              </Table.Cell>
              <Table.Cell>{participant.additionalDetails}</Table.Cell>
              <Table.Cell className="flex gap-4 items-center h-full">
                <EditTourParticipantDialog
                  tour={tour}
                  tourParticipant={participant}
                />
                <MoveTourParticipantsDialog
                  participantIds={[participant.id]}
                  tour={tour}
                />
                <DeleteTourParticipantsDialog
                  participantIds={[participant.id]}
                  tour={tour}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};
