import Decimal from "decimal.js";

export const PriceFormatter = {
  full(price: number) {
    return price.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  },
  noFraction(price: number) {
    return price.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  },
  localeCurrency(
    price: number,
    { currency, locale }: { currency: string; locale: string },
  ) {
    return price.toLocaleString(locale, { currency, style: "currency" });
  },
  toTwoDecimalPlaces(price: number | Decimal) {
    return new Decimal(price).toDecimalPlaces(2, Decimal.ROUND_DOWN);
  },
};
