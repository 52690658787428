import { EmptyState, Pagination } from "@twocontinents/dashboard/shared";
import { Star } from "lucide-react";

import { AttractionReview, BookingReview } from "../types";
import { AddManualReviewDialog } from "./add-manual-review-dialog";
import { ReviewsFilterBar } from "./reviews-filter-bar";
import { ReviewsTable } from "./reviews-table";

interface ReviewsTabProps {
  reviews: (AttractionReview | BookingReview)[];
  showReviewsNotFound: boolean;
  showReviewsTable: boolean;
  showPagination: boolean;
  totalPages: number;
}

export const ReviewsTab = ({
  reviews,
  showReviewsNotFound,
  showPagination,
  totalPages,
  showReviewsTable,
}: ReviewsTabProps) => {
  return (
    <>
      <ReviewsFilterBar />
      <AddManualReviewDialog />
      {showReviewsNotFound && (
        <EmptyState>
          <EmptyState.Icon>
            <Star size={40} />
          </EmptyState.Icon>
          <EmptyState.Header>No reviews</EmptyState.Header>
          <EmptyState.Paragraph>
            Check if the filters are correct and try again.
          </EmptyState.Paragraph>
        </EmptyState>
      )}
      {showReviewsTable && <ReviewsTable reviews={reviews} />}
      {showPagination && (
        <Pagination
          totalPages={totalPages}
          sizeLabel="Ilość zamówień na stronę"
        />
      )}
    </>
  );
};
