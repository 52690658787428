import { useGetAttractionReviews } from "../data-access";
import { useReviewsFiltersQueryParams } from "../hooks/use-reviews-filters-query-params";

export const useAttractionReviewsTab = () => {
  const { query } = useReviewsFiltersQueryParams();
  const { isLoading, paginatedReviews } = useGetAttractionReviews(query);

  const showReviewsNotFound =
    (paginatedReviews?.reviews?.length ?? 0) === 0 && !isLoading;
  const showPagination = Boolean(!isLoading && paginatedReviews);
  const showReviewsTable = Boolean(
    paginatedReviews?.reviews &&
      (paginatedReviews?.reviews?.length ?? 0) > 0 &&
      !isLoading,
  );

  return {
    paginatedReviews,
    showReviewsNotFound,
    showPagination,
    showReviewsTable,
  };
};
