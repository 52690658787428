import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";

const queryKey = ["internal-tickets"];

export const useGetAttractionsInternalTickets = () => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: () =>
      apiClient
        .GET("/private/attractions/ticket-counts")
        .then((response) => response.data),
  });

  return {
    attractionIdToInternalTicketCount:
      data?.attractionIdToInternalTicketCount ?? {},
    isLoading,
  };
};

export const useInvalidateGetAttractionsInternalTickets = () => {
  const queryClient = useQueryClient();

  const invalidate = async () => {
    await queryClient.invalidateQueries({
      queryKey,
    });
  };

  return {
    invalidate,
  };
};
