import { ChevronsUpDown } from "lucide-react";

import { cn } from "../../utils";
import { Button, Command, Label, Popover } from "../shadcn";
import {
  type ApartmentOption,
  useApartmentCombobox,
} from "./use-apartment-combobox";

// eslint-disable-next-line unicorn/prefer-export-from
export { ApartmentOption };

export interface ApartmentComboboxProps {
  apartments: ApartmentOption[];
  selectedId?: number;
  setSelectedOption: (value: ApartmentOption) => void;
  withLabel?: boolean;
}

export function ApartmentCombobox({
  selectedId,
  setSelectedOption,
  apartments,
  withLabel = false,
}: ApartmentComboboxProps) {
  const {
    apartmentsOptions,
    defaultApartmentOption,
    onSelect,
    open,
    setOpen,
    selectedOption,
  } = useApartmentCombobox(apartments, setSelectedOption, selectedId);

  const notFoundText = "Apartment not found.";
  const searchPlaceholder = "Search for apartment...";

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <Popover.Trigger asChild>
        <div className="flex flex-col gap-2 border-slate-700">
          {withLabel && <Label>Apartment</Label>}

          {(apartments?.length ?? 0) > 0 && (
            <Button
              type="button"
              role="combobox"
              aria-expanded={open}
              className=" justify-between"
            >
              {selectedOption?.description ??
                defaultApartmentOption.description}
              <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50 border-slate-700" />
            </Button>
          )}
        </div>
      </Popover.Trigger>
      <Popover.Content
        asChild
        align="start"
        className="w-80 p-0 bg-slate-950 text-slate-100 border-slate-700"
      >
        <Command
          className={cn("border-slate-700 font-sans  !overflow-y-hidden")}
        >
          <Command.Input placeholder={searchPlaceholder} />
          <Command.List>
            <Command.Empty>{notFoundText}</Command.Empty>
            <Command.Group className="border-slate-700 h-96 ">
              {apartmentsOptions.map((apartment) => {
                return (
                  <ApartmentSelectItem
                    key={apartment.id}
                    selectedOptionId={selectedOption.id}
                    {...apartment}
                    onSelect={onSelect}
                  />
                );
              })}
            </Command.Group>
          </Command.List>
        </Command>
      </Popover.Content>
    </Popover>
  );
}

interface ApartmentSelectItemProps {
  selectedOptionId: number;
  id: number;
  description: string;
  onSelect: (value: string) => void;
}

const ApartmentSelectItem = ({
  selectedOptionId,
  id,
  description,
  onSelect,
}: ApartmentSelectItemProps) => {
  const isSelected = selectedOptionId === id;
  return (
    <Command.Item
      className={cn(
        "cursor-pointer  aria-selected:bg-slate-800 aria-selected:text-white",
        {
          "bg-slate-600 text-white": isSelected,
          "text-slate-100": !isSelected,
        },
      )}
      value={description}
      onSelect={onSelect}
    >
      {description}
    </Command.Item>
  );
};
