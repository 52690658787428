import { ApiLocaleSelect } from "../../api-locale-select";
import { Label } from "../../shadcn";
import { useLocaleFilter } from "./use-locale-filter";

export const LocaleFilter = () => {
  const { locale, handleLocaleChange } = useLocaleFilter();

  return (
    <div>
      <Label>Locale</Label>
      <ApiLocaleSelect
        value={locale}
        onValueChange={handleLocaleChange}
        reset={() => handleLocaleChange(undefined)}
      />
    </div>
  );
};
