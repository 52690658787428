import { useMutation } from "@tanstack/react-query";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

import { useInvalidationGetApartmentExtraOptions } from "./get-extra-options";

type UpdateExtraOptionRequest =
  components["schemas"]["UpdateApartmentExtraOptionRequest"];

interface Props {
  apartmentId: number;
  extraOptionId: number;
  body: UpdateExtraOptionRequest;
}

export const useUpdateApartmentExtraOption = () => {
  const apiClient = usePrivateApiClient();
  const invalidate = useInvalidationGetApartmentExtraOptions();

  const { mutate, isPending } = useMutation({
    mutationFn: ({ body, extraOptionId }: Props) => {
      return apiClient.PATCH("/private/stays/apartments/extra-options/{id}", {
        body,
        params: {
          path: {
            id: extraOptionId,
          },
        },
      });
    },
    onSuccess: async (_, { apartmentId }) => {
      console.log(apartmentId);
      await invalidate(apartmentId);
      toast.success("Extra option has been updated successfully.");
    },
    onError: () => {
      toast.error("Extra option could not be updated");
    },
  });

  return {
    updateExtraOption: mutate,
    isPending,
  };
};
