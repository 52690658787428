/* eslint-disable max-lines-per-function */
import {
  useTableDropdown,
  useTableRowsSelect,
} from "@twocontinents/dashboard/shared";

import { AttractionOrderBase } from "../../../types/attraction-order-base";

export const useOrdersTable = (orders: AttractionOrderBase[]) => {
  const { areDropdownsOpened, handleDropdownOpenChange, openDropdown } =
    useTableDropdown();

  const mappedOrders = orders.map((order) => {
    const mostRecentReservation = order.reservations.toSorted(
      (a, b) =>
        new Date(a.reservationDate).getTime() -
        new Date(b.reservationDate).getTime(),
    )[0];
    return {
      ...order,
      reservationDate: mostRecentReservation.reservationDate,
      reservationTime: mostRecentReservation.reservationTime,
    };
  });

  const {
    handleSingleRowCheckboxChange,
    handleAllRowsCheckboxChange,
    selectedRows: selectedOrders,
    setSelectedRows: setSelectedOrders,
    areAllRowsSelected,
  } = useTableRowsSelect({ rows: mappedOrders });

  return {
    areDropdownsOpened,
    selectedOrders,
    setSelectedOrders,
    areAllOrdersSelected: areAllRowsSelected,
    openDropdown,
    mappedOrders,
    handleAllOrdersCheckboxChange: handleAllRowsCheckboxChange,
    handleSingleOrderCheckboxChange: handleSingleRowCheckboxChange,
    handleDropdownOpenChange,
  };
};
