import { Button, SettingCard } from "@twocontinents/dashboard/shared";

import { useRevalidateExternalApartment } from "../data-access";

interface RevalidateSectionProps {
  apartmentId: number;
}

export const RevalidateSection = ({ apartmentId }: RevalidateSectionProps) => {
  const { revalidateExternalApartment, isPending } =
    useRevalidateExternalApartment();

  const handleRevalidateButtonClick = () => {
    revalidateExternalApartment({ apartmentId });
  };

  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>Refresh apartment</SettingCard.Title>
        <SettingCard.Description>
          Reload apartment data from hostfully
        </SettingCard.Description>
      </SettingCard.Header>

      <Button
        className="w-fit"
        variant="outline"
        isLoading={isPending}
        onClick={handleRevalidateButtonClick}
      >
        Refresh
      </Button>
    </SettingCard>
  );
};
