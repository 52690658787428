/* eslint-disable max-lines-per-function */
import {
  ALL_FILTER_VALUE,
  OrderStatus,
  useOrderFiltersQueryParams,
  usePaginationAdjustment,
  usePaginationQueryParams,
} from "@twocontinents/dashboard/shared";
import { useCallback } from "react";
import { useDebounceValue } from "usehooks-ts";

import { useGetOrders } from "../../data-access";

export const useOrdersTab = () => {
  const { query, setQuery } = useOrderFiltersQueryParams();

  const [debouncedFirstName] = useDebounceValue(query?.customerName, 300);
  const [debouncedSurname] = useDebounceValue(query?.customerSurname, 300);
  const [debouncedEmail] = useDebounceValue(query?.customerEmail, 300);

  const { arePaginatedOrdersLoading, paginatedOrders } = useGetOrders({
    ...query,
    customerName: debouncedFirstName,
    customerSurname: debouncedSurname,
    customerEmail: debouncedEmail,
    status:
      query.status === ALL_FILTER_VALUE
        ? undefined
        : (query.status as OrderStatus),
  });

  const { page, size } = usePaginationQueryParams();

  usePaginationAdjustment({
    totalPages: paginatedOrders?.pagination?.totalPages,
  });

  const handleSizeSelect = useCallback(
    (size: number) => {
      setQuery((params) => ({ ...params, size }));
    },
    [setQuery],
  );

  const showOrdersNotFound =
    (paginatedOrders?.orders?.length ?? 0) === 0 && !arePaginatedOrdersLoading;
  const showOrders =
    paginatedOrders?.orders &&
    (paginatedOrders?.orders?.length ?? 0) > 0 &&
    !arePaginatedOrdersLoading;

  return {
    arePaginatedOrdersLoading,
    paginatedOrders,
    handleSizeSelect,
    page,
    size,
    showOrdersNotFound,
    showOrders,
  };
};
