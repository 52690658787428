import { useMutation, useQueryClient } from "@tanstack/react-query";
import { components } from "@twocontinents/shared";

import { OrderStatus } from "../types";
import { toast, usePrivateApiClient } from "../utils";

export type BatchOrdersUpdateRequest =
  components["schemas"]["UpdateOrdersRequest"];

export const useBatchOrdersUpdate = () => {
  const apiClient = usePrivateApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (request: BatchOrdersUpdateRequest) =>
      apiClient.PATCH("/private/orders/", {
        body: request,
      }),
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries();
      const status =
        variables.status === OrderStatus.NEW
          ? "niezrealizowane"
          : "zrealizowane";

      toast.success(`Zamówienia zostały zapisane jako ${status}`);
    },
    onError: () => {
      toast.error("Wystąpił błąd podczas aktualizacji zamówień");
    },
  });
};
