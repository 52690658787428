import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useUpdateOrder } from "../../../data-access";
import { OrderStatus, PaymentStatus } from "../../../types";

const OrderStatusesFormSchema = z.object({
  status: z.nativeEnum(OrderStatus),
  paymentConfirmed: z.boolean(),
});

export type OrderStatusesFormValues = z.infer<typeof OrderStatusesFormSchema>;

type Order = {
  id: number;
  status: OrderStatus;
  paymentStatus?: PaymentStatus;
  paymentConfirmed: boolean;
};
export interface OrderStatusesFormProps {
  order: Order;
}

export const useOrderStatusesForm = (order: Order) => {
  const { isPending, updateOrder } = useUpdateOrder(order.id);

  const form = useForm<OrderStatusesFormValues>({
    defaultValues: {
      status: order.status,
      paymentConfirmed: order.paymentConfirmed ?? false,
    },
    resolver: zodResolver(OrderStatusesFormSchema),
  });

  const onSubmit = form.handleSubmit((values) => {
    updateOrder(values);
  });

  return {
    isPending,
    form,
    onSubmit,
  };
};
