import { useQuery } from "@tanstack/react-query";

import { usePrivateApiClient } from "../utils";

export const useGetApartments = () => {
  const apiClient = usePrivateApiClient();
  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/stays/apartments/")
        .then((res) => res?.data?.apartments ?? []),
    queryKey: ["apartments"],
  });

  return { apartments: data ?? [], isLoading };
};
