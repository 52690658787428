import { useEffect } from "react";

import { usePaginationQueryParams } from "./use-pagination-query-params";

interface UsePaginationAdjustment {
  totalPages: number | undefined;
}
export const usePaginationAdjustment = ({
  totalPages,
}: UsePaginationAdjustment) => {
  const { page, setPage } = usePaginationQueryParams();

  useEffect(() => {
    if (totalPages && totalPages < page + 1) {
      setPage(0);
    }
  }, [totalPages, page, setPage]);
};
