import { DragEndEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { useGetApartments } from "@twocontinents/dashboard/shared";
import { Apartment } from "@twocontinents/dashboard/stays/apartments/shared";
import { FormEvent, useEffect, useState } from "react";

import { useUpdateApartmentsSequence } from "../../data-access";

export const useApartmentsSequence = () => {
  const { apartments } = useGetApartments();
  const { isPending, updateApartmentsSequence } = useUpdateApartmentsSequence();

  const [apartmentsSequence, setApartmentsSequence] = useState<Apartment[]>([]);

  useEffect(() => {
    setApartmentsSequence(
      apartments?.toSorted((a, b) => a.sequence - b.sequence) ?? [],
    );
  }, [apartments]);

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id === over?.id) return;
    if (!over || !active) return;

    setApartmentsSequence((items) => {
      const oldIndex = items
        .map((item) => item.id)
        .indexOf(Number.parseInt(active.id.toString()));

      const newIndex = items
        .map((item) => item.id)
        .indexOf(Number.parseInt(over.id.toString()));

      return arrayMove(items, oldIndex, newIndex);
    });
  }

  const sortableApartments = apartmentsSequence.map((apartment) => ({
    id: apartment.id,
    name: apartment.description,
  }));

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const apartmentToSequenceMap = Object.fromEntries(
      apartmentsSequence.map((apartment, index) => [apartment.id, index]),
    );
    updateApartmentsSequence({
      apartmentsSequence: apartmentToSequenceMap,
    });
  };

  return {
    sortableApartments,
    handleDragEnd,
    handleFormSubmit,
    isPending,
  };
};
