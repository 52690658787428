import { zodResolver } from "@hookform/resolvers/zod";
import {
  AttractionGroupAvailability,
  AttractionGroupExtraOptionAvailability,
  AttractionVariantActivationLog,
} from "@twocontinents/dashboard/attractions/shared";
import {
  ALL_ITEMS_ID,
  AttractionGroup,
  useOpened,
} from "@twocontinents/dashboard/shared";
import { DateFormatter } from "@twocontinents/shared";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useUpdateAttractionGroupAvailabilities } from "../../../../data-access";
import { useAttractionGroups } from "../../../../hooks";
import {
  AttractionGroupEntity,
  AttractionGroupEntityList,
  DateAvailability,
  ExtraOptionAvailability,
  ExtraOptionAvailabilityRequest,
  MergedAvailabilities,
} from "../../../../types";
import { ActivationLog } from "../../../../types/activation-log";

const AvailabilityFormSchema = (attractionGroups: AttractionGroupEntityList) =>
  z
    .object({
      groupId: z.number(),
      availability: z.object({
        date: z.string(),
        time: z.string().optional(),
        slotsAvailable: z.coerce.number().min(-1),
        extraOptions: z
          .object({
            extraOptionId: z.number(),
            available: z.coerce.boolean(),
          })
          .array(),
        activationLogs: z
          .object({
            variantId: z.coerce.number(),
            active: z.coerce.boolean(),
          })
          .array(),
      }),
      times: z.coerce.number().optional(),
      days: z.coerce.number().optional(),
    })
    .superRefine((data, ctx) => {
      const selectedGroup = attractionGroups.findById(data.groupId);
      if (selectedGroup?.isGroupTimed && !data.availability.time) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Time is required for timed groups",
          path: ["availability", "time"],
        });
      }
    });

export type AvailabilityForm = z.infer<
  ReturnType<typeof AvailabilityFormSchema>
>;

const defaultAvailability: DateAvailability = new DateAvailability({
  date: "",
  slotsAvailable: -1,
  time: undefined,
  extraOptions: [],
  activationLogs: [],
});

export const useAvailabilitySettings = (
  attractionGroups: AttractionGroup[],
  attractionId: number,
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  const { opened: activationLogsOpened, toggle: toggleActivationLogsOpened } =
    useOpened(false);
  const [mutatedAvailabilities, setMutatedAvailabilities] = useState<
    DateAvailability[]
  >([]);

  const { updateGroupAvailability, isPending } =
    useUpdateAttractionGroupAvailabilities();

  const form = useForm<AvailabilityForm>({
    resolver: zodResolver(
      AvailabilityFormSchema(new AttractionGroupEntityList(attractionGroups)),
    ),
    defaultValues: {
      availability: defaultAvailability,
      times: 1,
      days: 1,
    },
  });

  const selectedDate = form.watch("availability.date");

  const formRef = useRef<HTMLFormElement | null>(null);

  const { handleSubmit, watch, setValue } = form;

  const resetFormToDefaults = () => {
    setValue("availability.date", "");
    setValue("availability.time", undefined);
    setValue("times", 1);
    setValue("days", 1);
  };

  const onSubmit = handleSubmit(({ availability, times = 1, days = 1 }) => {
    const availabilities = generateAvailabilitiesInInterval(
      selectedGroup,
      availability,
      times,
      days,
    );

    setMutatedAvailabilities((prev) => {
      const mergedAvailabilities = new MergedAvailabilities({
        secondary: prev,
        primary: availabilities,
      });
      return mergedAvailabilities.merged;
    });

    resetFormToDefaults();
  });

  const onSubmitUnavailable = () => {
    setValue("availability.slotsAvailable", 0);
    formRef.current?.requestSubmit();
  };

  const { date, time } = watch("availability");
  const groupId = watch("groupId");

  const { selectedGroup } = useAttractionGroups({
    attractionGroups,
    groupId,
  });

  const times = selectedGroup?.times ?? [];
  const isGroupTimed = selectedGroup?.isGroupTimed ?? false;
  const extraOptions =
    selectedGroup?.extraOptions?.toSorted((a, b) => a.id - b.id) ?? [];

  const getAvailableSlotsByTime = (time: string) => {
    return (
      selectedGroup?.availabilities.find(
        (availability) =>
          availability.time === time && availability.date === selectedDate,
      )?.slotsAvailable ?? selectedGroup?.defaultSlotsAvailable
    );
  };

  const showSubmitButtons =
    form.formState.isValid && (isGroupTimed ? Boolean(time) : true);

  const saveMutatedAvailabilities = () => {
    const extraOptionAvailabilities = flatGroupedExtraOptionAvailabilities(
      mutatedAvailabilities,
    );

    const activationLogs = flatGroupedActivationLogs(mutatedAvailabilities);

    updateGroupAvailability({
      attractionId,
      groupId,
      body: {
        availabilities: mutatedAvailabilities.map((availability) => ({
          date: availability.date,
          time: availability.time,
          slotsAvailable: availability.slotsAvailable,
        })),
        extraOptionAvailabilities: extraOptionAvailabilities,
        activationLogs,
      },
    });

    setMutatedAvailabilities([]);
  };
  const allAvailabilities = mergeFetchedAndMutatedAvailabilities(
    mutatedAvailabilities,
    selectedGroup,
  );

  const addButtonDisabled = !selectedGroup || !selectedDate;

  useEffect(() => {
    if (selectedGroup) {
      const selectedAvailability = allAvailabilities.findByDatetime(date, time);

      const slotsAvailable =
        selectedAvailability?.slotsAvailable ??
        selectedGroup.defaultSlotsAvailable;

      const extraOptions: ExtraOptionAvailability[] = selectedAvailability
        ?.extraOptions?.length
        ? selectedAvailability.extraOptions.toSorted(
            (a, b) => a.extraOptionId - b.extraOptionId,
          )
        : selectedGroup.extraOptions.map((extraOption) => ({
            extraOptionId: extraOption.id,
            available: selectedAvailability?.isAvailable ?? true,
          }));

      const selectedAvailabilityActivationsLogs =
        selectedAvailability?.activationLogs
          ?.filter((log) =>
            selectedGroup.attractionVariants.some(
              (variant) => variant.id === log.variantId,
            ),
          )
          ?.toSorted((a, b) => a.variantId - b.variantId) ?? [];

      const activationLogs: ActivationLog[] =
        selectedAvailabilityActivationsLogs?.length
          ? selectedAvailabilityActivationsLogs
          : selectedGroup.attractionVariants.map((variant) => ({
              variantId: variant.id,
              active: true,
            }));

      setValue("availability.slotsAvailable", slotsAvailable);
      setValue("availability.extraOptions", extraOptions);
      setValue("availability.activationLogs", activationLogs);
    }
    // cannot pass form as a dependency, it will cause infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, time, selectedGroup]);

  return {
    form,
    onSubmit,
    isPending,
    times,
    isGroupTimed,
    extraOptions,
    attractionVariants:
      selectedGroup?.attractionVariants?.toSorted((a, b) => a.id - b.id) ?? [],
    mutatedAvailabilities,
    saveMutatedAvailabilities,
    onSubmitUnavailable,
    showSubmitButtons,
    formRef,
    activationLogsOpened,
    toggleActivationLogsOpened,
    getAvailableSlotsByTime,
    addButtonDisabled,
    selectedDate,
  };
};

const flatGroupedExtraOptionAvailabilities = (
  mutatedAvailabilities: DateAvailability[],
) => {
  const extraOptionAvailabilities: ExtraOptionAvailabilityRequest[] =
    mutatedAvailabilities.flatMap((availability) =>
      availability.extraOptions.flatMap((extraOptionsAvailability) => ({
        date: availability.date,
        time: availability.time,
        extraOptionId: extraOptionsAvailability.extraOptionId,
        available: extraOptionsAvailability.available,
      })),
    );
  return extraOptionAvailabilities;
};

const flatGroupedActivationLogs = (
  mutatedAvailabilities: DateAvailability[],
) => {
  return mutatedAvailabilities.flatMap((availability) =>
    availability.activationLogs.map((activationLog) => ({
      date: availability.date,
      time: availability.time,
      variantId: activationLog.variantId,
      active: activationLog.active,
    })),
  );
};

const generateAvailabilitiesInInterval = (
  selectedGroup: AttractionGroupEntity | undefined,
  availability: AvailabilityForm["availability"],
  times: number,
  days: number,
) => {
  const initialDate = dayjs(availability.date);

  // Determine time slots to use
  const timeSlots =
    availability.time === ALL_ITEMS_ID.toString()
      ? (selectedGroup?.times ?? [])
      : [availability.time];

  const availabilities: DateAvailability[] = [];

  timeSlots.forEach((time) => {
    for (let i = 0; i < times; i++) {
      const newDate = initialDate.add(days * i, "day");
      const formattedDate = DateFormatter.formatToYYYYMMDD(newDate);

      availabilities.push(
        new DateAvailability({
          ...availability,
          date: formattedDate,
          time,
        }),
      );
    }
  });

  return availabilities;
};

const mergeFetchedAndMutatedAvailabilities = (
  mutatedAvailabilities: DateAvailability[],
  selectedGroup?: AttractionGroupEntity,
) => {
  const fetchedAvailabilities: DateAvailability[] =
    mergeSlotsAndExtraOptionsAvailabilitiesWithActivationLogs(
      selectedGroup?.availabilities ?? [],
      selectedGroup?.extraOptionAvailabilities ?? [],
      selectedGroup?.activationLogs ?? [],
      selectedGroup?.defaultSlotsAvailable,
    );

  return new MergedAvailabilities({
    secondary: fetchedAvailabilities,
    primary: mutatedAvailabilities,
  });
};

const mergeSlotsAndExtraOptionsAvailabilitiesWithActivationLogs = (
  availabilities: AttractionGroupAvailability[],
  extraOptionsAvailabilities: AttractionGroupExtraOptionAvailability[],
  activationLogs: AttractionVariantActivationLog[],
  defaultSlotsAvailable?: number,
) => {
  const extraOptionsMap = new Map<string, ExtraOptionAvailability[]>();
  const activationLogsMap = new Map<string, ActivationLog[]>();

  extraOptionsAvailabilities.forEach(
    ({ date, time, available, extraOptionId }) => {
      const availabilityId = DateAvailability.id(date, time);
      const extraOptionAvailability = { extraOptionId, available };
      const previousExtraOptions = extraOptionsMap.get(availabilityId) ?? [];
      extraOptionsMap.set(availabilityId, [
        ...previousExtraOptions,
        extraOptionAvailability,
      ]);
    },
  );

  activationLogs.forEach(({ variantId, date, time, active }) => {
    const availabilityId = DateAvailability.id(date, time);
    const activationLog = { variantId, active };
    const previousActivationLogs = activationLogsMap.get(availabilityId) ?? [];
    activationLogsMap.set(availabilityId, [
      ...previousActivationLogs,
      activationLog,
    ]);
  });

  const datePresentInExtraOptionsAndNotInSlots = [
    ...new Set<string>(
      extraOptionsAvailabilities
        .filter(
          ({ date, time }) =>
            !availabilities.some(
              (availability) =>
                availability.date === date && availability.time === time,
            ),
        )
        .map(({ date, time }) => DateAvailability.id(date, time)),
    ),
  ];

  const mappedAvailabilities = availabilities.map((availability) => {
    const id = DateAvailability.id(availability.date, availability.time);
    return new DateAvailability({
      ...availability,
      extraOptions: extraOptionsMap.get(id) ?? [],
      activationLogs: activationLogsMap.get(id) ?? [],
    });
  });

  const defaultAvailabilities = datePresentInExtraOptionsAndNotInSlots.map(
    (date) =>
      new DateAvailability({
        date,
        slotsAvailable: defaultSlotsAvailable ?? -1,
        extraOptions: extraOptionsMap.get(date) ?? [],
        activationLogs: activationLogsMap.get(date) ?? [],
      }),
  );

  return [...mappedAvailabilities, ...defaultAvailabilities];
};
