import {
  AttractionCombobox,
  Button,
  CustomerFilter,
  LocaleFilter,
  OrderIdFilter,
  PaymentStatusFilter,
  SingleOrRangeDatePicker,
} from "@twocontinents/dashboard/shared";

import { useOrderFilters } from "./use-order-filters";

export const OrderFilters = () => {
  const {
    attractionsOptions,
    selectedId,
    handleAttractionComboboxSelect,
    reservationDate,
    handleReservationDateChange,
    setReservationDateTo,
    setReservationDateFrom,
    showRangePicker,
    toggleRangePicker,
    reservationDateTo,
    reservationDateFrom,
    setDateRangeAsThisMonth,
  } = useOrderFilters();

  return (
    <div className="flex flex-col mt-4">
      <div className="gap-5 flex  items-start border-slate-700 flex-wrap">
        <div className="flex  gap-4">
          <AttractionCombobox
            attractions={attractionsOptions}
            selectedId={selectedId}
            setSelectedOption={handleAttractionComboboxSelect}
          />
          <Button onClick={setDateRangeAsThisMonth}>This month</Button>
        </div>
        <div className="flex gap-4 flex-wrap">
          <CustomerFilter />
          <OrderIdFilter />
        </div>
      </div>
      <div className="flex gap-8 items-center my-4">
        <PaymentStatusFilter />

        <div className="flex flex-col gap-2">
          <SingleOrRangeDatePicker
            showRangePicker={showRangePicker}
            toggleRangePicker={toggleRangePicker}
            dateFrom={reservationDateFrom}
            setDateFrom={setReservationDateFrom}
            dateTo={reservationDateTo}
            setDateTo={setReservationDateTo}
            time={undefined}
            setTime={undefined}
            date={reservationDate}
            setDate={handleReservationDateChange}
          />
        </div>
        <LocaleFilter />
      </div>
    </div>
  );
};
