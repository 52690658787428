import {
  AgeGroupSelect,
  AttractionVariantSelectByGroups,
} from "@twocontinents/dashboard/attractions/shared";
import {
  AttractionGroup,
  CurrencyCodeSelect,
  Form,
  FormItem,
  Input,
  Select,
  SettingCard,
} from "@twocontinents/dashboard/shared";
import { AgeGroupType, CurrencyCode } from "@twocontinents/shared";

import { useAgeGroupsSettings } from "./use-age-groups-settings";

interface AgeGroupsSettingsProps {
  attractionId: number;
  attractionGroups: AttractionGroup[];
}

export const AgeGroupsSettings = ({
  attractionId,
  attractionGroups,
}: AgeGroupsSettingsProps) => {
  const {
    ageGroups,
    form,
    isPending,
    onSubmit,
    isSubmitDisabled,
    selectedVariantHasInternalTicket,
  } = useAgeGroupsSettings(attractionId, attractionGroups);

  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>Age groups settings</SettingCard.Title>
        <SettingCard.Description>
          Change variant's description
        </SettingCard.Description>
      </SettingCard.Header>
      <Form {...form}>
        <form onSubmit={onSubmit}>
          <Form.Fields>
            <Form.Field
              control={form.control}
              name="variantId"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Variant</FormItem.Label>
                    <FormItem.Control>
                      <AttractionVariantSelectByGroups
                        attractionGroups={attractionGroups}
                        onValueChange={field.onChange}
                      />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />

            <Form.Field
              control={form.control}
              name="id"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Age group</FormItem.Label>
                    <FormItem.Control>
                      <AgeGroupSelect
                        ageGroups={ageGroups}
                        onValueChange={field.onChange}
                        value={field?.value?.toString()}
                      />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            <Form.Field
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Description</FormItem.Label>
                    <FormItem.Control>
                      <Input {...field} />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />

            <Form.Field
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Type</FormItem.Label>
                    <FormItem.Control>
                      <Select
                        onValueChange={(value) => field.onChange(value)}
                        value={field.value}
                      >
                        <Select.Trigger className="max-w-md h-max min-h-10">
                          <Select.Value placeholder="Select age group type" />
                        </Select.Trigger>
                        <Select.Content>
                          {Object.values(AgeGroupType).map((ageGroupType) => (
                            <Select.Item
                              value={ageGroupType}
                              key={ageGroupType}
                            >
                              {ageGroupType}
                            </Select.Item>
                          ))}
                        </Select.Content>
                      </Select>
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />

            <Form.Field
              control={form.control}
              name="price"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Price</FormItem.Label>
                    <FormItem.Control>
                      <Input type="number" {...field} />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            <Form.Field
              control={form.control}
              name="providerPrice"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Provider price</FormItem.Label>
                    <FormItem.Control>
                      <Input type="number" {...field} />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            {selectedVariantHasInternalTicket && (
              <>
                <Form.Field
                  control={form.control}
                  name="internalProviderPrice"
                  render={({ field }) => (
                    <FormItem>
                      <FormItem.Row>
                        <FormItem.Label>
                          Provider price for internal tickets
                        </FormItem.Label>
                        <FormItem.Control>
                          <Input type="number" {...field} />
                        </FormItem.Control>
                      </FormItem.Row>
                      <FormItem.Message />
                    </FormItem>
                  )}
                />
                <Form.Field
                  control={form.control}
                  name="alertTicketsAmountThreshold"
                  render={({ field }) => (
                    <FormItem>
                      <FormItem.Row>
                        <FormItem.Label>
                          Trigger alert when tickets amount is less than
                        </FormItem.Label>
                        <FormItem.Control>
                          <Input type="number" {...field} />
                        </FormItem.Control>
                      </FormItem.Row>
                      <FormItem.Message />
                    </FormItem>
                  )}
                />
              </>
            )}
            <Form.Field
              control={form.control}
              name="providerCurrency"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label>Provider currency</FormItem.Label>
                    <FormItem.Control>
                      <CurrencyCodeSelect
                        value={field.value}
                        currencies={Object.values(CurrencyCode)}
                        onValueChange={field.onChange}
                      />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
          </Form.Fields>
          <SettingCard.Footer>
            <SettingCard.Description>
              Age to is optional (if not provided, it will be considered as
              infinity)
            </SettingCard.Description>
            <SettingCard.Button
              isLoading={isPending}
              disabled={isSubmitDisabled}
            >
              Submit
            </SettingCard.Button>
          </SettingCard.Footer>
        </form>
      </Form>
    </SettingCard>
  );
};
