import { zodResolver } from "@hookform/resolvers/zod";
import { CurrencyCode } from "@twocontinents/shared";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useUpdateApartmentExtraOption } from "../../data-access";
import { ApartmentExtraOption } from "../../types";

const UpdateExtraOptionFormSchema = z.object({
  extraOptionId: z.number().nonnegative(),
  description: z.string(),
  price: z.coerce.number(),
  providerPrice: z.coerce.number(),
  currency: z.nativeEnum(CurrencyCode),
  providerCurrency: z.nativeEnum(CurrencyCode),
});

type UpdateExtraOptionFormSchema = z.infer<typeof UpdateExtraOptionFormSchema>;

export const useExtraOptionsSettings = (
  extraOptions: ApartmentExtraOption[],
  apartmentId: number,
) => {
  const { isPending, updateExtraOption } = useUpdateApartmentExtraOption();

  const form = useForm<UpdateExtraOptionFormSchema>({
    resolver: zodResolver(UpdateExtraOptionFormSchema),
  });

  const {
    handleSubmit,
    watch,
    setValue,
    formState: { isValid },
  } = form;

  const extraOptionId = watch("extraOptionId");
  const selectedExtraOption = extraOptions.find(
    (extraOption) => extraOption.id === extraOptionId,
  );

  useEffect(() => {
    if (selectedExtraOption) {
      setValue("description", selectedExtraOption.description);
      setValue("price", selectedExtraOption.price);
      setValue("currency", selectedExtraOption.currency);
      setValue("providerPrice", selectedExtraOption.providerPrice);
      setValue("providerCurrency", selectedExtraOption.providerCurrency);
    }
  }, [selectedExtraOption, setValue]);

  const handleFormSubmit = handleSubmit(({ extraOptionId, ...body }) => {
    updateExtraOption({
      apartmentId,
      extraOptionId,
      body,
    });
  });

  const isButtonDisabled = isPending || !extraOptionId || !isValid;

  return {
    isPending,
    handleFormSubmit,
    form,
    isButtonDisabled,
  };
};
