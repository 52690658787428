import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useUpdateApartment } from "../../data-access";

const MaxGuestsAmountSettings = z.object({
  maxGuestsAmount: z.coerce.number().min(0),
  maxAdultsAmount: z.coerce.number().min(0),
});

type MaxGuestsAmountSettings = z.infer<typeof MaxGuestsAmountSettings>;

export const useMaxGuestsAmountSettings = (
  apartmentId: number,
  currentMaxGuestsAmount: number,
  currentMaxAdultsAmount: number,
) => {
  const { updateApartment, isPending } = useUpdateApartment();

  const form = useForm<MaxGuestsAmountSettings>({
    resolver: zodResolver(MaxGuestsAmountSettings),
    defaultValues: {
      maxAdultsAmount: currentMaxAdultsAmount,
      maxGuestsAmount: currentMaxGuestsAmount,
    },
  });

  const { handleSubmit } = form;

  const onSubmit = handleSubmit((body) => {
    updateApartment({
      apartmentId,
      body,
    });
  });

  return {
    form,
    onSubmit,
    isPending,
  };
};
