import { useMutation } from "@tanstack/react-query";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";

import { useInvalidateReviews } from "../hooks";

interface Props {
  reviewId: number;
}

export const useDeleteReviewPicture = () => {
  const apiClient = usePrivateApiClient();
  const { invalidateReviews } = useInvalidateReviews();

  const { mutate, isPending } = useMutation({
    mutationFn: ({ reviewId }: Props) => {
      return apiClient.DELETE("/private/reviews/pictures/{id}", {
        params: {
          path: {
            id: reviewId,
          },
        },
      });
    },
    onSuccess: async () => {
      toast.success("Review picture has been deleted.");
      await invalidateReviews();
    },
    onError: () => {
      toast.error("An error occurred while deleting the review picture.");
    },
  });

  return {
    deleteReviewPicture: mutate,
    isPending,
  };
};
