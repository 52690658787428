import {
  CustomerFilter,
  LocaleFilter,
  OrderIdFilter,
  PaymentStatusFilter,
} from "@twocontinents/dashboard/shared";

export const FilterBar = () => {
  return (
    <div className="flex flex-col my-4">
      <div className="gap-4 flex items-end border-slate-700 flex-wrap">
        <CustomerFilter />
        <OrderIdFilter idIdentifier="bookingId" />
        <PaymentStatusFilter />
        <LocaleFilter />
      </div>
    </div>
  );
};
