import { zodResolver } from "@hookform/resolvers/zod";
import { routes, toast } from "@twocontinents/dashboard/shared";
import {
  useAuth,
  useSignIn as useSignInMutation,
} from "@twocontinents/dashboard/shared/auth";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

const SignInSchema = z.object({
  username: z.string().min(2, {
    message: "Nazwa użytkownika musi mieć przynajmniej 3 znaki.",
  }),
  password: z.string().min(3, {
    message: "Hasło musi mieć przynajmniej 3 znaki.",
  }),
});

type SignInSchema = z.infer<typeof SignInSchema>;

export const useSignIn = () => {
  const navigate = useNavigate();
  const { setToken } = useAuth();
  const form = useForm<SignInSchema>({
    resolver: zodResolver(SignInSchema),
    mode: "onBlur",
  });

  const { signIn, isPending } = useSignInMutation();

  const onSubmit = form.handleSubmit((body) => {
    signIn(body, {
      onError: () => {
        toast.error("Invalid credentials");
      },
      onSuccess: (accessToken) => {
        setToken(accessToken);
        toast.success("Logged in successfuly");
        navigate(routes.home);
      },
    });
  });

  return {
    form,
    onSubmit,
    isPending,
  };
};
