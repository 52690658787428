import dayjs, { Dayjs } from "dayjs";

import { DateFormatter } from "../../../utils/formatting/date-formatter";

export class TimeSettingsEntity {
  public constructor(
    private _timed: boolean,
    private _pickupTimed: boolean,
    private _timeZone: string,
    private _timeInterval?: number,
    private _timeStart?: string,
    private _timeFinish?: string,
  ) {}

  public generateTimeSlots(selectedDate?: Dayjs): string[] {
    if (
      (!this._timed && !this._pickupTimed) ||
      !this._timeStart ||
      !this._timeFinish ||
      !this._timeInterval
    ) {
      return [];
    }

    const { timed, pickupTimed, timeFinish, timeInterval, timeStart } = this;

    if (
      (!timed && !pickupTimed) ||
      !timeStart ||
      !timeFinish ||
      !timeInterval
    ) {
      return [];
    }

    const timeSlots: string[] = [];

    const startTime = dayjs(timeStart, "HH:mm:ss");
    let finishTime = dayjs(timeFinish, "HH:mm:ss");

    if (finishTime.isBefore(startTime)) {
      finishTime = finishTime.add(1, "day");
    }

    let currentTime = startTime;

    while (currentTime.isBefore(finishTime) || currentTime.isSame(finishTime)) {
      timeSlots.push(currentTime.format("HH:mm:ss"));
      currentTime = currentTime.add(timeInterval, "minutes");
    }

    const timeInAttractionTz = dayjs().tz(this._timeZone);
    const isToday = selectedDate?.isSame(timeInAttractionTz, "day");

    return timeSlots.filter((time) => {
      if (isToday) {
        return time > DateFormatter.formatToHHmmss(timeInAttractionTz);
      }
      return true;
    });
  }

  public get timed(): boolean {
    return this._timed;
  }

  public get timeInterval(): number | undefined {
    return this._timeInterval;
  }

  public get timeZone(): string {
    return this._timeZone;
  }

  public get timeStart(): string | undefined {
    return this._timeStart;
  }

  public get timeFinish(): string | undefined {
    return this._timeFinish;
  }

  public get pickupTimed(): boolean {
    return this._pickupTimed;
  }
}
