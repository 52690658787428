import { useMutation } from "@tanstack/react-query";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

import { useInvalidateReviews } from "../hooks";

type ModerateReviewBody = components["schemas"]["ReviewModerateRequest"];

interface ModerateReviewProps {
  reviewId: number;
  body: ModerateReviewBody;
}

export const useModerateReview = () => {
  const apiClient = usePrivateApiClient();

  const { invalidateReviews } = useInvalidateReviews();

  const { mutate, isPending } = useMutation({
    mutationFn: ({ reviewId, body }: ModerateReviewProps) => {
      return apiClient.PATCH("/private/reviews/{id}/moderate", {
        params: {
          path: {
            id: reviewId,
          },
        },
        body,
      });
    },
    onSuccess: async () => {
      toast.success("Review has been moderated.");
      await invalidateReviews();
    },
    onError: () => {
      toast.error("An error occurred while moderating the review.");
    },
  });

  return {
    moderateReview: mutate,
    isPending,
  };
};
