import { useCallback, useState } from "react";

interface UseTableRowsSelect {
  rows: { id: number }[];
}

export const useTableRowsSelect = ({ rows }: UseTableRowsSelect) => {
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const handleAllRowsCheckboxChange = useCallback(
    (checked: boolean) => {
      if (checked) {
        setSelectedRows(rows.map((row) => row.id) ?? []);
      } else {
        setSelectedRows([]);
      }
    },
    [rows],
  );

  const handleSingleRowCheckboxChange = useCallback(
    (checked: boolean, orderId: number) => {
      if (checked) {
        setSelectedRows((previous) => [...previous, orderId]);
      } else {
        setSelectedRows((previous) => previous.filter((id) => id !== orderId));
      }
    },
    [],
  );

  const areAllRowsSelected =
    rows.length === selectedRows.length && rows.length > 0;

  return {
    selectedRows,
    setSelectedRows,
    handleSingleRowCheckboxChange,
    handleAllRowsCheckboxChange,
    areAllRowsSelected,
  };
};
