import { useMutation } from "@tanstack/react-query";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";

import { useInvalidateGetCoupons } from "../hooks";

export const useDeleteCoupon = () => {
  const apiClient = usePrivateApiClient();
  const { invalidate } = useInvalidateGetCoupons();

  const { mutate, isPending } = useMutation({
    mutationFn: (couponId: number) =>
      apiClient
        .DELETE(`/private/coupons/{id}`, {
          params: {
            path: {
              id: couponId,
            },
          },
        })
        .then((response) => response.data),
    onSuccess: async () => {
      toast.success("Coupon deleted successfully");
      await invalidate();
    },
    onError: () => {
      toast.error("Failed to delete coupon");
    },
  });

  return {
    deleteCoupon: mutate,
    isDeletingCoupon: isPending,
  };
};
