import { useGetTour } from "@twocontinents/dashboard/attractions/shared";
import { NOT_SELECTED_ID } from "@twocontinents/dashboard/shared";
import { useBoolean } from "usehooks-ts";

export const useTourCombobox = (
  onTourInputChange: (tourId: number | undefined) => void,
  selectedTourId?: number,
) => {
  const openChangeTourCombobox = useBoolean(false);

  const { tour: selectedTour } = useGetTour(selectedTourId ?? NOT_SELECTED_ID);
  const onTourSelect = (currentValue: string) => {
    onTourInputChange(Number(currentValue));
    openChangeTourCombobox.setFalse();
  };

  return {
    openChangeTourCombobox,
    selectedTour,
    onTourSelect,
  };
};
