import {
  BatchOrdersUpdateButtons,
  Checkbox,
  Table,
  useTableRowsSelect,
} from "@twocontinents/dashboard/shared";

import { BookingOrderBase } from "../types";
import { BookingTableRow } from "./booking-table-row";

interface BookingOrdersTableProps {
  orders: BookingOrderBase[];
}
export const BookingOrdersTable = ({ orders }: BookingOrdersTableProps) => {
  const {
    handleSingleRowCheckboxChange,
    handleAllRowsCheckboxChange,
    selectedRows,
    setSelectedRows,
    areAllRowsSelected,
  } = useTableRowsSelect({ rows: orders });

  return (
    <>
      <Table className="bg-slate-950 ">
        <Table.Header className="w-full rounded-3xl ">
          <Table.Row className="border-slate-700">
            <Table.Head className="w-[32px]">
              <Checkbox
                id="select-all"
                className="data-[state=checked]:bg-slate-200 border-slate-200 data-[state=checked]:text-slate-800 w-6 h-6"
                checked={areAllRowsSelected}
                onCheckedChange={handleAllRowsCheckboxChange}
              />
            </Table.Head>
            <Table.Head className="min-w-14 py-5">ID</Table.Head>
            <Table.Head className="py-5">Apartment</Table.Head>
            <Table.Head className="py-5">Order date</Table.Head>
            <Table.Head className="min-w-32 py-5">Check-in</Table.Head>
            <Table.Head className="min-w-32 py-5">Check-out</Table.Head>
            <Table.Head className="py-5">Full name</Table.Head>
            <Table.Head className="py-5">Locale</Table.Head>
            <Table.Head className="py-5">Open</Table.Head>
            <Table.Head className="py-5">Payment status</Table.Head>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {orders.map((order) => (
            <BookingTableRow
              key={order.id}
              order={order}
              isRowChecked={selectedRows.includes(order.id)}
              onRowCheckedChange={handleSingleRowCheckboxChange}
            />
          ))}
        </Table.Body>
      </Table>
      {orders.length > 0 && (
        <BatchOrdersUpdateButtons
          selectedOrderIds={selectedRows}
          setSelectedOrderIds={setSelectedRows}
        />
      )}
    </>
  );
};
