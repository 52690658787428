import { Layout, ShopOffering, Tabs } from "@twocontinents/dashboard/shared";

import { useCouponFiltersQueryParams } from "../hooks";
import { ApartmentCouponsTab } from "./apartment-coupons-tab";
import { AttractionCouponsTab } from "./attraction-coupons-tab";

export const CouponsManagement = () => {
  const { query, setQuery } = useCouponFiltersQueryParams();
  const { offerType } = query;

  const setTab = (value: ShopOffering) => {
    setQuery({ offerType: value });
  };

  return (
    <Layout
      header={{
        title: "Coupons management",
        description: `Manage your coupons here`,
      }}
    >
      <Tabs
        className="w-full"
        value={offerType}
        defaultValue={ShopOffering.ATTRACTION}
      >
        <Tabs.List>
          <Tabs.Trigger
            value={ShopOffering.ATTRACTION}
            onClick={() => setTab(ShopOffering.ATTRACTION)}
          >
            Attractions
          </Tabs.Trigger>
          <Tabs.Trigger
            value={ShopOffering.STAY}
            onClick={() => setTab(ShopOffering.STAY)}
          >
            Apartments
          </Tabs.Trigger>
        </Tabs.List>
        <Tabs.Content value={ShopOffering.ATTRACTION}>
          <AttractionCouponsTab />
        </Tabs.Content>

        <Tabs.Content value={ShopOffering.STAY}>
          <ApartmentCouponsTab />
        </Tabs.Content>
      </Tabs>
    </Layout>
  );
};
