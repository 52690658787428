import { Input } from "../../shadcn";
import { useCustomerFilter } from "./use-customer-filter";

export const CustomerFilter = () => {
  const {
    customerName,
    customerSurname,
    customerEmail,
    handleNameChange,
    handleSurnameChange,
    handleEmailChange,
  } = useCustomerFilter();

  return (
    <div className="flex gap-4 flex-wrap">
      <Input
        name="firstName"
        className="w-44"
        placeholder="Name"
        value={customerName}
        onChange={handleNameChange}
      />
      <Input
        name="lastName"
        className="w-44"
        placeholder="Surname"
        value={customerSurname}
        onChange={handleSurnameChange}
      />
      <Input
        name="email"
        className="w-44"
        placeholder="Email"
        value={customerEmail}
        onChange={handleEmailChange}
      />
    </div>
  );
};
