import { Button } from "../shadcn";
import { useBatchOrdersUpdateButtons } from "./use-batch-orders-update-buttons";

interface Props {
  selectedOrderIds: number[];
  setSelectedOrderIds: (ids: number[]) => void;
}

export const BatchOrdersUpdateButtons = ({
  selectedOrderIds,
  setSelectedOrderIds,
}: Props) => {
  const { handleSetAsNotRealized, handleSetAsRealized, areButtonsDisabled } =
    useBatchOrdersUpdateButtons(selectedOrderIds, setSelectedOrderIds);

  return (
    <div className="flex gap-4 my-4">
      <Button
        variant="positive"
        disabled={areButtonsDisabled}
        onClick={handleSetAsRealized}
      >
        Mark as realized
      </Button>
      <Button
        variant="destructive"
        disabled={areButtonsDisabled}
        onClick={handleSetAsNotRealized}
      >
        Mark as not realized
      </Button>
    </div>
  );
};
