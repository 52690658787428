import { ReviewsTab } from "./reviews-tab";
import { useAttractionReviewsTab } from "./use-attraction-reviews-tab";

export const AttractionReviewsTab = () => {
  const {
    paginatedReviews,
    showPagination,
    showReviewsTable,
    showReviewsNotFound,
  } = useAttractionReviewsTab();

  return (
    <ReviewsTab
      reviews={paginatedReviews?.reviews ?? []}
      showReviewsNotFound={showReviewsNotFound}
      showPagination={showPagination}
      totalPages={paginatedReviews?.pagination?.totalPages ?? 0}
      showReviewsTable={showReviewsTable}
    />
  );
};
