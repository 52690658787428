import {
  Button,
  Checkbox,
  CurrencyCodeSelect,
  DateTimePicker,
  Form,
  FormItem,
  Input,
  Label,
  MultiSelect,
  SettingCard,
  ShopOffering,
} from "@twocontinents/dashboard/shared";
import { CurrencyCode, DateFormatter } from "@twocontinents/shared";
import { type FC } from "react";

import { useAddCouponForm } from "./use-add-coupon-form";

interface AddCouponFormProps {
  setShowForm: (showForm: boolean) => void;
}

export const AddCouponForm: FC<AddCouponFormProps> = ({ setShowForm }) => {
  const {
    form,
    handleSubmit,
    infinityUsages,
    isCreatingCoupon,
    onSubmit,
    register,
    selectedVariants,
    setSelectedVariants,
    variantsOptions,
    setValue,
    closeForm,
    selectedApartments,
    handleApartmentsChange,
    apartmentsOptions,
    offerType,
    values,
  } = useAddCouponForm(setShowForm);

  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>Add coupon</SettingCard.Title>
        <SettingCard.Description>
          Set coupon name, value and activity date.
        </SettingCard.Description>
      </SettingCard.Header>
      <Form {...form}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex gap-6 flex-col justify-between"
        >
          <Form.Field
            control={form.control}
            name="code"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-64">Code</FormItem.Label>
                  <Input {...field} placeholder="DUBAJ2023" className="w-fit" />
                </FormItem.Row>
              </FormItem>
            )}
          />

          <Form.Field
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-64">
                    Description
                  </FormItem.Label>
                  <Input
                    {...field}
                    placeholder="Description"
                    className="w-fit"
                  />
                </FormItem.Row>
              </FormItem>
            )}
          />
          {offerType === ShopOffering.ATTRACTION ? (
            <Form.Field
              control={form.control}
              name="attractionVariantIds"
              render={() => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label className="min-w-64">
                      Variants
                    </FormItem.Label>
                    <MultiSelect
                      options={variantsOptions}
                      selected={selectedVariants}
                      onChange={setSelectedVariants}
                      placeholder="Choose Attraction Variants"
                      className=" w-96"
                    />
                  </FormItem.Row>
                </FormItem>
              )}
            />
          ) : (
            <>
              <Form.Field
                control={form.control}
                name="apartmentIds"
                render={() => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label className="min-w-64">
                        Apartments
                      </FormItem.Label>
                      <MultiSelect
                        options={apartmentsOptions}
                        selected={selectedApartments}
                        onChange={handleApartmentsChange}
                        placeholder="Choose apartment"
                        className=" w-96"
                      />
                    </FormItem.Row>
                  </FormItem>
                )}
              />
              <Form.Field
                control={form.control}
                name="minimumStays"
                render={({ field }) => (
                  <FormItem>
                    <FormItem.Row>
                      <FormItem.Label className="min-w-64">
                        Minimum Stay
                      </FormItem.Label>
                      <Input {...field} placeholder="6" className="w-fit" />
                    </FormItem.Row>
                  </FormItem>
                )}
              />
            </>
          )}

          <Form.Field
            control={form.control}
            name="infinityUsages"
            render={() => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-64">
                    Infinity usages
                  </FormItem.Label>
                  <Checkbox
                    className="bg-slate-200"
                    id="infinityUsages"
                    {...register("infinityUsages")}
                    onCheckedChange={(e) => {
                      setValue("availableUsages", 1);
                      setValue("infinityUsages", e as boolean);
                    }}
                  />
                </FormItem.Row>
              </FormItem>
            )}
          />

          {!infinityUsages && (
            <Form.Field
              control={form.control}
              name="availableUsages"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label className="min-w-64">
                      Available Usages
                    </FormItem.Label>
                    <Input {...field} placeholder="100" className="w-fit" />
                  </FormItem.Row>
                </FormItem>
              )}
            />
          )}

          <Form.Field
            control={form.control}
            name="value"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-64">Value</FormItem.Label>
                  <Input
                    {...field}
                    placeholder="100"
                    type="number"
                    className="w-fit"
                  />
                </FormItem.Row>
              </FormItem>
            )}
          />

          <Form.Field
            control={form.control}
            name="currency"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-64">Currency</FormItem.Label>
                  <FormItem.Control>
                    <CurrencyCodeSelect
                      value={field.value}
                      currencies={Object.values(CurrencyCode)}
                      onValueChange={field.onChange}
                    />
                  </FormItem.Control>
                </FormItem.Row>
              </FormItem>
            )}
          />

          <Form.Field
            control={form.control}
            name="startDate"
            render={({ field }) => (
              <FormItem.Row>
                <Label className="min-w-64">
                  Start date (in your timezone)
                </Label>
                <DateTimePicker
                  disablePastDates
                  date={field.value ? new Date(field.value) : undefined}
                  setDate={(date) =>
                    field.onChange(DateFormatter.formatToYYYYMMDD(date))
                  }
                  displayTimeInput
                  time={values.startTime}
                  setTime={(time) => setValue("startTime", time)}
                />{" "}
              </FormItem.Row>
            )}
          />

          <Form.Field
            control={form.control}
            name="endDate"
            render={({ field }) => (
              <FormItem.Row>
                <Label className="min-w-64">End date (in your timezone)</Label>
                <DateTimePicker
                  disablePastDates
                  date={field.value ? new Date(field.value) : undefined}
                  setDate={(date) =>
                    field.onChange(DateFormatter.formatToYYYYMMDD(date))
                  }
                  displayTimeInput
                  time={values.endTime}
                  setTime={(time) => setValue("endTime", time)}
                />
              </FormItem.Row>
            )}
          />

          <Form.Field
            control={form.control}
            name="reservationPeriodStartDate"
            render={({ field }) => (
              <FormItem.Row>
                <Label className="min-w-64">
                  Reservation period start date (in your timezone)
                </Label>
                <DateTimePicker
                  disablePastDates
                  date={field.value ? new Date(field.value) : undefined}
                  setDate={(date) =>
                    field.onChange(DateFormatter.formatToYYYYMMDD(date))
                  }
                  displayTimeInput
                  time={values.reservationPeriodStartTime}
                  setTime={(time) =>
                    setValue("reservationPeriodStartTime", time)
                  }
                />
              </FormItem.Row>
            )}
          />

          <Form.Field
            control={form.control}
            name="reservationPeriodEndDate"
            render={({ field }) => (
              <FormItem.Row>
                <Label className="min-w-64">
                  Reservation period end date (in your timezone)
                </Label>
                <DateTimePicker
                  disablePastDates
                  date={field.value ? new Date(field.value) : undefined}
                  setDate={(date) =>
                    field.onChange(DateFormatter.formatToYYYYMMDD(date))
                  }
                  displayTimeInput
                  time={values.reservationPeriodEndTime}
                  setTime={(time) => setValue("reservationPeriodEndTime", time)}
                />
              </FormItem.Row>
            )}
          />

          <SettingCard.Footer>
            <SettingCard.Description>
              Leaving the start and end date fields empty means no restrictions
            </SettingCard.Description>
            <SettingCard.ButtonGroup>
              <Button variant="destructive" onClick={closeForm}>
                Cancel
              </Button>
              <SettingCard.Button isLoading={isCreatingCoupon}>
                Create
              </SettingCard.Button>
            </SettingCard.ButtonGroup>
          </SettingCard.Footer>
        </form>
      </Form>
    </SettingCard>
  );
};
