import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";

export const useGetApartmentExtraOptions = (id: number) => {
  const apiClient = usePrivateApiClient();
  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/stays/apartments/{id}/extra-options", {
          params: { path: { id } },
        })
        .then((res) => res?.data?.extraOptions ?? []),
    queryKey: ["private-apartment-extra-options", id],
  });

  return { extraOptions: data ?? [], isLoading };
};

export const useInvalidationGetApartmentExtraOptions = () => {
  const queryClient = useQueryClient();

  return (apartmentId: number) => {
    queryClient.invalidateQueries({
      queryKey: ["private-apartment-extra-options", apartmentId],
    });
  };
};
