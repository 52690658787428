import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";

import { GetApartmentCouponsFilter } from "../types";

export const useGetApartmentCoupons = (filters?: GetApartmentCouponsFilter) => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryKey: ["private-apartment-coupons", filters ?? "all"],
    queryFn: () =>
      apiClient
        .GET("/private/coupons/apartment", {
          params: {
            query: filters,
          },
        })
        .then((response) => response.data),
  });

  return {
    coupons: data?.coupons ?? [],
    pagination: data?.pagination,
    arePaginatedCouponsLoading: isLoading,
  };
};

export const useInvalidateGetApartmentCoupons = () => {
  const queryClient = useQueryClient();

  const invalidate = async () => {
    await queryClient.invalidateQueries({
      queryKey: ["private-apartment-coupons"],
    });
  };

  return {
    invalidate,
  };
};
