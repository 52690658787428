import {
  Form,
  FormItem,
  Input,
  SettingCard,
} from "@twocontinents/dashboard/shared";
import { CurrencyCodeSelect } from "@twocontinents/dashboard/shared";
import { CurrencyCode, Money } from "@twocontinents/shared";

import { useDefaultPriceSettings } from "./use-default-price-settings";

interface DefaultPriceSettingsProps {
  apartmentId: number;
  currentDefaultPrice: Money;
}

export const DefaultPriceSettings = ({
  apartmentId,
  currentDefaultPrice,
}: DefaultPriceSettingsProps) => {
  const { form, onSubmit, isPending } = useDefaultPriceSettings(
    apartmentId,
    currentDefaultPrice,
  );

  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>Default price settings</SettingCard.Title>
        <SettingCard.Description>
          Change apartment's default price settings
        </SettingCard.Description>
      </SettingCard.Header>
      <Form {...form}>
        <form onSubmit={onSubmit}>
          <Form.Fields>
            <Form.Field
              control={form.control}
              name="defaultPrice.amount"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label className="min-w-36">
                      Default price
                    </FormItem.Label>
                    <FormItem.Control>
                      <Input {...field} type={"number"} className="max-w-md" />
                    </FormItem.Control>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            <Form.Field
              control={form.control}
              name="defaultPrice.currency"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label className="min-w-36">
                      Currency
                    </FormItem.Label>
                    <FormItem.Control>
                      <CurrencyCodeSelect
                        value={field.value}
                        currencies={Object.values(CurrencyCode)}
                        onValueChange={field.onChange}
                      />
                    </FormItem.Control>
                  </FormItem.Row>
                </FormItem>
              )}
            />
          </Form.Fields>
          <SettingCard.Footer>
            <SettingCard.Description>
              This setting will change default price settings for this apartment
            </SettingCard.Description>
            <SettingCard.Button isLoading={isPending}>
              Submit
            </SettingCard.Button>
          </SettingCard.Footer>
        </form>
      </Form>
    </SettingCard>
  );
};
