import { useRef } from "react";

import { PropsWithClassName } from "../../../types";
import { cn } from "../../../utils";
import { Label } from "../label";
import { TimePickerSingleInput } from "./time-picker-single-input";

interface TimePickerProps extends PropsWithClassName {
  value: string | undefined;
  onChange: (time?: string) => void;
}

export const TimePicker = ({ value, onChange, className }: TimePickerProps) => {
  const hourRef = useRef<HTMLInputElement>(null);
  const minuteRef = useRef<HTMLInputElement>(null);

  return (
    <div className={cn("flex items-end gap-2", className)}>
      <div className="grid gap-1 text-center">
        <Label htmlFor="hours" className="text-xs">
          Hours
        </Label>
        <TimePickerSingleInput
          picker="hours"
          time={value}
          setTime={onChange}
          ref={hourRef}
          onRightFocus={() => minuteRef.current?.focus()}
        />
      </div>

      <div className="grid gap-1 text-center">
        <Label htmlFor="minutes" className="text-xs">
          Minutes
        </Label>
        <TimePickerSingleInput
          picker="minutes"
          time={value}
          setTime={onChange}
          ref={minuteRef}
          onLeftFocus={() => hourRef.current?.focus()}
        />
      </div>
    </div>
  );
};
