import {
  ApartmentOption,
  useGetApartments,
  useGetAttractions,
} from "@twocontinents/dashboard/shared";
import {
  ALL_ITEMS_ID,
  AttractionOption,
} from "@twocontinents/dashboard/shared";
import { DateFormatter } from "@twocontinents/shared";
import dayjs from "dayjs";
import { type ChangeEvent } from "react";

import { useReviewsFiltersQueryParams } from "../../hooks/use-reviews-filters-query-params";

export enum ReviewVisibilityFilter {
  ALL = "-1",
  HIDDEN = "0",
  VISIBLE = "1",
}

const getVisibilityStatus = (hidden: boolean | undefined) => {
  if (hidden === undefined) {
    return ReviewVisibilityFilter.ALL;
  }
  return hidden
    ? ReviewVisibilityFilter.HIDDEN
    : ReviewVisibilityFilter.VISIBLE;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useReviewsFilterBar = () => {
  const { query, setQuery } = useReviewsFiltersQueryParams();

  const { attractions } = useGetAttractions();
  const { apartments } = useGetApartments();

  const selectedAttractionId = query.attractionId ?? -1;
  const selectedApartmentId = query.apartmentId ?? -1;

  const defaultVisibilityStatus = getVisibilityStatus(query.hidden);

  const handleVisibilityStatusSelect = (value: ReviewVisibilityFilter) => {
    const visibilityStatusAsNumber = Number(value);
    if (visibilityStatusAsNumber === -1) {
      setQuery({ hidden: undefined });
      return;
    }
    setQuery({ hidden: visibilityStatusAsNumber === 0 });
  };

  const {
    createdAfter,
    createdBefore,
    customerEmail,
    hidden,
    maxRating,
    minRating,
  } = query;

  const handleAttractionComboboxSelect = (value?: AttractionOption) => {
    if (value?.id === ALL_ITEMS_ID) {
      setQuery({ attractionId: undefined });
      return;
    }
    setQuery({ attractionId: value?.id });
  };

  const handleApartmentComboboxSelect = (value?: ApartmentOption) => {
    if (value?.id === ALL_ITEMS_ID) {
      setQuery({ apartmentId: undefined });
      return;
    }
    setQuery({ apartmentId: value?.id });
  };

  const handleCustomerEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery({ customerEmail: event.target.value });
  };

  const handleMinRatingChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery({ minRating: event.target.valueAsNumber || undefined });
  };

  const handleMaxRatingChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery({ maxRating: event.target.valueAsNumber || undefined });
  };

  const handleCreatedAfterDateChange = (date?: Date) => {
    setQuery({
      createdAfter: date ? dayjs(date).format("YYYY-MM-DD") : undefined,
    });
  };

  const handleCreatedBeforeDateChange = (date?: Date) => {
    setQuery({
      createdBefore: date ? dayjs(date).format("YYYY-MM-DD") : undefined,
    });
  };

  const createdAfterDate = createdAfter
    ? DateFormatter.formatToNativeDate(createdAfter)
    : undefined;

  const createdBeforeDate = createdBefore
    ? DateFormatter.formatToNativeDate(createdBefore)
    : undefined;

  return {
    offerType: query.offerType,
    attractions,
    createdAfterDate,
    createdBeforeDate,
    customerEmail,
    handleAttractionComboboxSelect,
    handleCreatedAfterDateChange,
    handleCreatedBeforeDateChange,
    handleCustomerEmailChange,
    handleMaxRatingChange,
    handleMinRatingChange,
    hidden,
    maxRating,
    minRating,
    selectedAttractionId,
    selectedApartmentId,
    handleApartmentComboboxSelect,
    defaultVisibilityStatus,
    handleVisibilityStatusSelect,
    apartments,
  };
};
