import { Form, SettingCard, Tabs } from "@twocontinents/dashboard/shared";

import { StatisticsTab as StatisticsTabEnum } from "../../types";
import { StatisticsTab } from "./statistics-tab";
import { useStatistics } from "./use-statistics";

export const Statistics = () => {
  const { form, tab, onTabChange, orderStatistics, financialLogStatistics } =
    useStatistics();

  return (
    <Form {...form}>
      <SettingCard>
        <SettingCard.Header>
          <SettingCard.Title>Statistics</SettingCard.Title>
          <SettingCard.Description>Review Statistics</SettingCard.Description>
        </SettingCard.Header>

        <Tabs
          value={tab}
          onValueChange={onTabChange}
          defaultValue={StatisticsTabEnum.Today}
        >
          <Tabs.List className="flex-col md:flex-row w-full md:w-auto h-auto">
            <Tabs.Trigger
              className="w-full py-2.5 md:py-1.5"
              value={StatisticsTabEnum.Today}
            >
              Today
            </Tabs.Trigger>
            <Tabs.Trigger
              className="w-full py-2.5 md:py-1.5"
              value={StatisticsTabEnum.ThisMonth}
            >
              This month
            </Tabs.Trigger>
            <Tabs.Trigger
              className="w-full py-2.5 md:py-1.5"
              value={StatisticsTabEnum.Range}
            >
              Range
            </Tabs.Trigger>
            <Tabs.Trigger
              className="w-full py-2.5 md:py-1.5"
              value={StatisticsTabEnum.All}
            >
              All
            </Tabs.Trigger>
          </Tabs.List>
          <Tabs.Content value={StatisticsTabEnum.Today}>
            <StatisticsTab
              orderStatistics={orderStatistics}
              financialLogStatistics={financialLogStatistics}
            />
          </Tabs.Content>
          <Tabs.Content value={StatisticsTabEnum.ThisMonth}>
            <StatisticsTab
              orderStatistics={orderStatistics}
              financialLogStatistics={financialLogStatistics}
            />
          </Tabs.Content>
          <Tabs.Content value={StatisticsTabEnum.All}>
            <StatisticsTab
              orderStatistics={orderStatistics}
              financialLogStatistics={financialLogStatistics}
            />
          </Tabs.Content>
          <Tabs.Content value={StatisticsTabEnum.Range}>
            <StatisticsTab
              orderStatistics={orderStatistics}
              financialLogStatistics={financialLogStatistics}
            />
          </Tabs.Content>
        </Tabs>
      </SettingCard>
    </Form>
  );
};
