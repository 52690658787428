import { ReviewsTab } from "./reviews-tab";
import { useBookingReviewsTab } from "./use-booking-reviews-tab";

export const BookingReviewsTab = () => {
  const {
    paginatedReviews,
    showReviewsTable,
    showReviewsNotFound,
    showPagination,
  } = useBookingReviewsTab();

  return (
    <ReviewsTab
      reviews={paginatedReviews?.reviews ?? []}
      showReviewsNotFound={showReviewsNotFound}
      showPagination={showPagination}
      totalPages={paginatedReviews?.pagination?.totalPages ?? 0}
      showReviewsTable={showReviewsTable}
    />
  );
};
