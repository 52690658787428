import {
  Button,
  CurrencyCodeSelect,
  Form,
  FormItem,
  Input,
  SettingCard,
} from "@twocontinents/dashboard/shared";
import { CurrencyCode } from "@twocontinents/shared";
import { DateRange } from "react-day-picker";

import { ApartmentCalendar } from "./apartment-calendar";
import { useCalendarSettings } from "./use-calendar-settings";

interface CalendarSettingsProps {
  apartmentId: number;
}

export const CalendarSettings = ({ apartmentId }: CalendarSettingsProps) => {
  const {
    form,
    formattedDateTo,
    month,
    setMonth,
    calendar,
    formattedDateFrom,
    handleRangeChange,
    pricesPlaceholder,
    maximumStayPlaceholder,
    minimumStayPlaceholder,
    setAvailable,
    setNotAvailable,
    available,
    priceSaveButtonDisabled,
    availabilitySaveButtonDisabled,
    minimumStaySaveButtonDisabled,
    maximumStaySaveButtonDisabled,
    handleAvailabilitySave,
    handleMinimumStaySave,
    handleMaximumStaySave,
    handlePriceSave,
  } = useCalendarSettings(apartmentId);
  return (
    <SettingCard>
      <SettingCard.Header>
        <SettingCard.Title>Calendar settings</SettingCard.Title>
        <SettingCard.Description>
          Manage your calendar settings
        </SettingCard.Description>
      </SettingCard.Header>
      <Form {...form}>
        <form>
          <Form.Fields>
            <Form.Field
              control={form.control}
              name="available"
              render={() => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label className="min-w-32">
                      Available
                    </FormItem.Label>
                    <FormItem.Control>
                      <div className="flex gap-2">
                        <Button
                          variant="positive"
                          className={
                            available ? "" : "bg-gray-600 hover:bg-gray-600"
                          }
                          onClick={setAvailable}
                          type="button"
                        >
                          Available
                        </Button>
                        <Button
                          variant="destructive"
                          className={
                            available === false
                              ? ""
                              : "bg-gray-600 hover:bg-gray-600"
                          }
                          onClick={setNotAvailable}
                          type="button"
                        >
                          Not available
                        </Button>
                      </div>
                    </FormItem.Control>
                    <Button
                      disabled={availabilitySaveButtonDisabled}
                      className="ml-auto"
                      type="button"
                      variant="outline"
                      onClick={handleAvailabilitySave}
                    >
                      Save
                    </Button>
                  </FormItem.Row>
                </FormItem>
              )}
            />
            <Form.Field
              control={form.control}
              name="price"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label className="min-w-32">Price</FormItem.Label>
                    <FormItem.Control>
                      <Input
                        placeholder={pricesPlaceholder}
                        {...field}
                        type={"number"}
                        className="max-w-md"
                      />
                    </FormItem.Control>
                    <Form.Field
                      control={form.control}
                      name="currency"
                      render={({ field }) => (
                        <FormItem>
                          <FormItem.Row>
                            <FormItem.Control>
                              <CurrencyCodeSelect
                                value={field.value}
                                currencies={Object.values(CurrencyCode)}
                                onValueChange={field.onChange}
                              />
                            </FormItem.Control>
                            <Button
                              disabled={priceSaveButtonDisabled}
                              type="button"
                              variant="outline"
                              onClick={handlePriceSave}
                            >
                              Save
                            </Button>
                          </FormItem.Row>
                          <FormItem.Message />
                        </FormItem>
                      )}
                    />
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />

            <Form.Field
              control={form.control}
              name="minimumStay"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label className="min-w-32">
                      Minimum stay
                    </FormItem.Label>
                    <FormItem.Control>
                      <Input
                        placeholder={minimumStayPlaceholder}
                        {...field}
                        type={"number"}
                        className="max-w-md"
                      />
                    </FormItem.Control>
                    <Button
                      type="button"
                      variant="outline"
                      disabled={minimumStaySaveButtonDisabled}
                      onClick={handleMinimumStaySave}
                    >
                      Save
                    </Button>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />
            <Form.Field
              control={form.control}
              name="maximumStay"
              render={({ field }) => (
                <FormItem>
                  <FormItem.Row>
                    <FormItem.Label className="min-w-32">
                      Maximum stay
                    </FormItem.Label>
                    <FormItem.Control>
                      <Input
                        placeholder={maximumStayPlaceholder}
                        {...field}
                        type={"number"}
                        className="max-w-md"
                      />
                    </FormItem.Control>
                    <Button
                      disabled={maximumStaySaveButtonDisabled}
                      type="button"
                      variant="outline"
                      onClick={handleMaximumStaySave}
                    >
                      Save
                    </Button>
                  </FormItem.Row>
                  <FormItem.Message />
                </FormItem>
              )}
            />

            <div className="w-full flex justify-center">
              <ApartmentCalendar
                dateFrom={formattedDateFrom}
                dateTo={formattedDateTo}
                onChange={(e) => handleRangeChange(e as DateRange)}
                calendarEntries={calendar?.entries ?? []}
                month={month}
                onMonthChange={setMonth}
              />
            </div>
          </Form.Fields>
        </form>
      </Form>
    </SettingCard>
  );
};
