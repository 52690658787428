import { Layout, Tabs } from "@twocontinents/dashboard/shared";

import { ApartmentsListing } from "./apartments-listing";
import { ApartmentsSequence } from "./apartments-sequence";

export const ApartmentIndexPage = () => {
  return (
    <Layout
      header={{
        title: `Manage Apartments`,
        description: "Browse and manage apartments",
      }}
    >
      <Tabs defaultValue="table">
        <Tabs.List className="self-center">
          <Tabs.Trigger value="table">Table</Tabs.Trigger>
          <Tabs.Trigger value="sequence">Sequence</Tabs.Trigger>
        </Tabs.List>
        <Tabs.Content value="table">
          <ApartmentsListing />
        </Tabs.Content>
        <Tabs.Content value="sequence">
          <ApartmentsSequence />
        </Tabs.Content>
      </Tabs>
    </Layout>
  );
};
