"use client";

export const isHeic = (file: File) =>
  file.type === "image/heic" || file.name.toLowerCase().endsWith(".heic");

export const convertHeicToJpeg = async (file: File) => {
  const { default: heic2any } = await import("heic2any");

  const blob = await heic2any({
    blob: file,
    toType: "image/jpeg",
    quality: 0.92,
  });

  return new File([blob as Blob], file.name.replace(/\.heic$/i, ".jpg"), {
    type: "image/jpeg",
  });
};
