import {
  ApartmentCombobox,
  AttractionCombobox,
  DatePicker,
  Input,
  Label,
  Select,
  ShopOffering,
} from "@twocontinents/dashboard/shared";

import {
  ReviewVisibilityFilter,
  useReviewsFilterBar,
} from "./use-reviews-filter-bar";

export const ReviewsFilterBar = () => {
  const {
    attractions,
    selectedApartmentId,
    selectedAttractionId,
    handleAttractionComboboxSelect,
    handleApartmentComboboxSelect,
    createdAfterDate,
    createdBeforeDate,
    customerEmail,
    handleCreatedAfterDateChange,
    handleCreatedBeforeDateChange,
    handleCustomerEmailChange,
    maxRating,
    minRating,
    handleMaxRatingChange,
    handleMinRatingChange,
    defaultVisibilityStatus,
    handleVisibilityStatusSelect,
    apartments,
    offerType,
  } = useReviewsFilterBar();

  return (
    <div className="mb-4 flex gap-4 items-end border-slate-700 flex-wrap">
      <div className="flex gap-4 flex-wrap">
        <Input
          name="customerEmail"
          className="w-44"
          placeholder="mail@wp.pl"
          value={customerEmail}
          onChange={handleCustomerEmailChange}
        />
        <Input
          name="minRating"
          className="w-28"
          type="number"
          step={0.5}
          placeholder="Min rating"
          value={minRating}
          onChange={handleMinRatingChange}
        />
        <Input
          name="maxRating"
          className="w-28"
          type="number"
          step={0.5}
          placeholder="Max rating"
          value={maxRating}
          onChange={handleMaxRatingChange}
        />
      </div>
      {offerType === ShopOffering.ATTRACTION ? (
        <AttractionCombobox
          attractions={attractions}
          selectedId={selectedAttractionId}
          setSelectedOption={handleAttractionComboboxSelect}
        />
      ) : (
        <ApartmentCombobox
          apartments={apartments}
          selectedId={selectedApartmentId}
          setSelectedOption={handleApartmentComboboxSelect}
        />
      )}
      <div className="w-fit  justify-start flex flex-col gap-2">
        <Label>Visibility</Label>
        <Select
          defaultValue={defaultVisibilityStatus}
          onValueChange={handleVisibilityStatusSelect}
        >
          <Select.Trigger className="w-44">
            <Select.Value placeholder="Wszystkie" />
          </Select.Trigger>
          <Select.Content>
            <Select.Item value={ReviewVisibilityFilter.ALL}>All</Select.Item>
            <Select.Item value={ReviewVisibilityFilter.VISIBLE}>
              Visible
            </Select.Item>
            <Select.Item value={ReviewVisibilityFilter.HIDDEN}>
              Hidden
            </Select.Item>
          </Select.Content>
        </Select>
      </div>
      <div className="flex flex-col gap-2">
        <Label className="inline-flex justify-between">Review Date from</Label>
        <DatePicker
          date={createdAfterDate}
          setDate={handleCreatedAfterDateChange}
        />
      </div>
      <div className="flex flex-col gap-2">
        <Label className="inline-flex justify-between">Review Date to</Label>
        <DatePicker
          date={createdBeforeDate}
          setDate={handleCreatedBeforeDateChange}
        />
      </div>
    </div>
  );
};
