import { ApiLocale } from "@twocontinents/shared";

import { useOrderFiltersQueryParams } from "../../../hooks";

export const useLocaleFilter = () => {
  const { query, setQuery } = useOrderFiltersQueryParams();

  const handleLocaleChange = (locale: ApiLocale | undefined | " ") => {
    if (locale === " ") {
      return setQuery({ locale: undefined });
    }
    setQuery({ locale });
  };

  return {
    locale: query.locale,
    handleLocaleChange,
  };
};
