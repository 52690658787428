import { DateFormatter } from "@twocontinents/shared";
import dayjs from "dayjs";
import { Calendar as CalendarIcon } from "lucide-react";

import { cn } from "../../utils";
import { Button, Calendar, Popover, TimePicker } from "../shadcn";
import { useDateTimePicker } from "./use-date-time-picker";

interface DateTimePickerProps {
  date: Date | undefined;
  setDate: (date?: Date) => void;
  time: string | undefined;
  setTime: (time?: string) => void;
  displayTimeInput?: boolean;
  disablePastDates?: boolean;
}

export function DateTimePicker({
  date,
  setDate,
  time,
  setTime,
  displayTimeInput = true,
  disablePastDates = false,
}: DateTimePickerProps) {
  const { handleSelect, selectedDateTime } = useDateTimePicker({
    date,
    setDate,
    time,
  });

  return (
    <Popover>
      <Popover.Trigger asChild className="z-10">
        <Button
          className={cn(
            "w-full max-w-sm justify-start text-left font-normal",
            !date && "text-muted-foreground",
          )}
        >
          {selectedDateTime ? (
            `${DateFormatter.formatToDDMMYYYY(selectedDateTime)} ${time ?? ""}`
          ) : (
            <span>Pick date</span>
          )}
          <CalendarIcon className="h-4 w-4" />
        </Button>
      </Popover.Trigger>
      <Popover.Content className="w-auto p-0">
        <Calendar
          disabled={{
            before: disablePastDates
              ? dayjs().toDate()
              : dayjs().subtract(10, "year").toDate(),
          }}
          mode="single"
          selected={date}
          onSelect={handleSelect}
          initialFocus
        />
        {displayTimeInput && (
          <TimePicker value={time} onChange={setTime} className="px-4 pb-4" />
        )}

        {!selectedDateTime && <p className="pb-4 px-4 text-sm">Pick date.</p>}
      </Popover.Content>
    </Popover>
  );
}
