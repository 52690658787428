import { DragAndDrop, SettingCard } from "@twocontinents/dashboard/shared";

import { useApartmentsSequence } from "./use-apartments-sequence";

export const ApartmentsSequence = () => {
  const { handleDragEnd, handleFormSubmit, isPending, sortableApartments } =
    useApartmentsSequence();

  return (
    <SettingCard className="my-4">
      <SettingCard.Header>
        <SettingCard.Title>Sequence settings</SettingCard.Title>
        <SettingCard.Description>
          Set the sequence of apartments
        </SettingCard.Description>
      </SettingCard.Header>
      <form onSubmit={handleFormSubmit}>
        <DragAndDrop items={sortableApartments} onDragEnd={handleDragEnd} />
        <SettingCard.Footer>
          <SettingCard.Description>
            This setting will change the sequence of apartments.
          </SettingCard.Description>
          <SettingCard.Button isLoading={isPending}>Submit</SettingCard.Button>
        </SettingCard.Footer>
      </form>
    </SettingCard>
  );
};
