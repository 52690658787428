import {
  usePaginationAdjustment,
  usePaginationQueryParams,
} from "@twocontinents/dashboard/shared";
import { useEffect } from "react";
import { useDebounceValue } from "usehooks-ts";

import { useGetBookingOrders } from "../data-access/get-booking-orders";
import { useBookingOrderFiltersQueryParams } from "../hooks";

export const useBookingsTab = () => {
  const { query, setQuery } = useBookingOrderFiltersQueryParams();

  const [debouncedFirstName] = useDebounceValue(query?.customerName, 300);
  const [debouncedSurname] = useDebounceValue(query?.customerSurname, 300);
  const [debouncedEmail] = useDebounceValue(query?.customerEmail, 300);

  const { paginatedOrders, isLoading } = useGetBookingOrders({
    ...query,
    customerName: debouncedFirstName,
    customerSurname: debouncedSurname,
    customerEmail: debouncedEmail,
  });

  const { page, size } = usePaginationQueryParams();

  usePaginationAdjustment({
    totalPages: paginatedOrders?.pagination?.totalPages,
  });

  return {
    paginatedOrders,
    isLoading,
    page,
    size,
  };
};
