import { useOrderFiltersQueryParams } from "../../../hooks";
import { PaymentStatusFilterType } from "../../../types";

const getPaymentStatus = (paymentConfirmed: boolean | undefined) => {
  if (paymentConfirmed === undefined) {
    return PaymentStatusFilterType.ALL;
  }
  return paymentConfirmed
    ? PaymentStatusFilterType.CONFIRMED
    : PaymentStatusFilterType.NOT_CONFIRMED;
};
export const usePaymentStatusFilter = () => {
  const { query, setQuery } = useOrderFiltersQueryParams();

  const defaultValue = getPaymentStatus(query.paymentConfirmed);

  const handleSelect = (value: PaymentStatusFilterType) => {
    const paymentStatusAsNumber = Number(value);
    if (paymentStatusAsNumber === -1) {
      setQuery({ paymentConfirmed: undefined });
      return;
    }
    setQuery({ paymentConfirmed: !!paymentStatusAsNumber });
  };

  return { defaultValue, handleSelect };
};
