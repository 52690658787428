import { Button, Pagination } from "@twocontinents/dashboard/shared";
import { useState } from "react";

import { AttractionCoupon, Coupon } from "../types";
import { AddCouponForm } from "./add-coupon-form";
import { CouponsTable } from "./coupons-table/coupons-table";

interface CouponsTabProps {
  arePaginatedCouponsLoading: boolean;
  coupons: Coupon[];
  totalPages: number;
}

export const CouponsTab = ({
  coupons,
  arePaginatedCouponsLoading,
  totalPages,
}: CouponsTabProps) => {
  const [showForm, setShowForm] = useState<boolean>(false);

  const showPagination = !arePaginatedCouponsLoading && totalPages > 0;
  const showCouponsTable = totalPages > 0 && !arePaginatedCouponsLoading;
  return (
    <>
      {!showForm && (
        <Button
          onClick={() => setShowForm(true)}
          className="w-fit self-end mt-2"
        >
          Add coupon
        </Button>
      )}
      {showForm && <AddCouponForm setShowForm={setShowForm} />}
      {arePaginatedCouponsLoading && <div>Loading...</div>}
      {showCouponsTable && <CouponsTable coupons={coupons} />}
      {showPagination && (
        <Pagination totalPages={totalPages} sizeLabel="Coupons per page" />
      )}
    </>
  );
};
