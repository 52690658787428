import { PropsWithChildren } from "react";

import { cn } from "../../utils";
import { Aside } from "./aside";
import { Navbar } from "./navbar";

interface LayoutProps extends PropsWithChildren {
  header: {
    title: string;
    description?: string;
  };
}

export const Layout = ({ header, children }: LayoutProps) => {
  return (
    <>
      <main
        className={cn(
          "flex min-h-screen flex-col bg-slate-950 font-sans text-slate-100",
        )}
      >
        <Navbar />
        <section className="flex flex-col">
          <header className="mx-auto container border-b border-b-slate-800 py-8 2xl:px-44 3xl:px-52">
            <div className="container mx-auto self-center p-4 md:p-0">
              <h1 className=" text-3xl font-medium">{header.title}</h1>
              {header.description && (
                <p className="my-2 leading-relaxed text-slate-400">
                  {header.description}
                </p>
              )}
            </div>
          </header>
          <div className="container relative flex flex-col md:flex-row  gap-12 self-center 2xl:px-44 3xl:px-52">
            <Aside />
            <article className="flex flex-col grow gap-6 py-12 min-h-screen">
              {children}
            </article>
          </div>
        </section>
      </main>
    </>
  );
};

const LayoutContent = ({ children }: PropsWithChildren) => {
  return <div className="flex flex-col gap-6 py-2">{children}</div>;
};

Layout.Content = LayoutContent;
