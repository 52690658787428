import { PaymentStatusFilterType } from "../../../types";
import { Label, Select } from "../../shadcn";
import { usePaymentStatusFilter } from "./use-payment-status-filter";

export const PaymentStatusFilter = () => {
  const { defaultValue, handleSelect } = usePaymentStatusFilter();

  return (
    <div className="w-fit justify-start flex flex-col gap-2">
      <Label>Payment status</Label>
      <Select defaultValue={defaultValue} onValueChange={handleSelect}>
        <Select.Trigger className="w-44">
          <Select.Value placeholder="Wszystkie" />
        </Select.Trigger>
        <Select.Content>
          <Select.Item value={PaymentStatusFilterType.ALL}>All</Select.Item>
          <Select.Item value={PaymentStatusFilterType.CONFIRMED}>
            Only Paid
          </Select.Item>
          <Select.Item value={PaymentStatusFilterType.NOT_CONFIRMED}>
            Only Not Paid
          </Select.Item>
        </Select.Content>
      </Select>
    </div>
  );
};
