import {
  useInvalidateGetAttractionReviews,
  useInvalidateGetBookingReviews,
} from "../data-access";

export const useInvalidateReviews = () => {
  const { invalidate: invalidateAttractionReviews } =
    useInvalidateGetAttractionReviews();
  const { invalidate: invalidateBookingReviews } =
    useInvalidateGetBookingReviews();

  const invalidateReviews = async () => {
    await invalidateAttractionReviews();
    await invalidateBookingReviews();
  };

  return {
    invalidateReviews,
  };
};
