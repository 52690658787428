import { useMutation } from "@tanstack/react-query";
import { toast } from "@twocontinents/dashboard/shared";
import { useAuth } from "@twocontinents/dashboard/shared/auth";
import { components, env } from "@twocontinents/shared";

import { useInvalidateReviews } from "../hooks";

type AddManualReviewBody = components["schemas"]["CreateManualReviewRequest"];

interface Props {
  body: AddManualReviewBody;
  pictures: File[];
}

const createReview = async ({ body, pictures }: Props, token: string) => {
  const formData = new FormData();

  Object.entries(body).forEach(([key, value]) => {
    formData.append(key, String(value));
  });

  pictures.forEach((picture) => {
    formData.append("pictures", picture);
  });

  return await fetch(`${env.API_URL}/api/v1/private/reviews/`, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (!res.ok) {
      return res.json().then((err) => {
        throw err;
      });
    }
    return res;
  });
};

export const useCreateManualReview = () => {
  const { token } = useAuth();

  const { invalidateReviews } = useInvalidateReviews();

  const { mutate, isPending } = useMutation({
    mutationFn: (props: Props) => createReview(props, token ?? ""),
    onSuccess: async () => {
      toast.success("Review created successfully");
      await invalidateReviews();
    },
    onError: () => {
      toast.error("Error creating review");
    },
  });

  return {
    createManualReview: mutate,
    isPending,
  };
};
