import { forwardRef, useEffect, useMemo, useState } from "react";

import { cn } from "../../../utils";
import { Input } from "../input";
import {
  Period,
  TimePickerType,
  getArrowByType,
  getDateByType,
  setDateByType,
} from "./time-picker.utils";

export interface TimePickerInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  picker: TimePickerType;
  time?: string; // HH:mm format
  setTime: (time: string) => void;
  period?: Period;
  onRightFocus?: () => void;
  onLeftFocus?: () => void;
}

export const TimePickerSingleInput = forwardRef<
  HTMLInputElement,
  TimePickerInputProps
>(
  (
    {
      className,
      type = "tel",
      value,
      id,
      name,
      time = "00:00",
      setTime,
      onChange,
      onKeyDown,
      picker,
      period,
      onLeftFocus,
      onRightFocus,
      ...props
    },
    ref,
  ) => {
    const [flag, setFlag] = useState<boolean>(false);
    const [prevIntKey, setPrevIntKey] = useState<string>("0");

    useEffect(() => {
      if (flag) {
        const timer = setTimeout(() => {
          setFlag(false);
        }, 2000);

        return () => clearTimeout(timer);
      }
    }, [flag]);

    const [hours, minutes] = time.split(":").map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);

    const calculatedValue = useMemo(() => {
      return getDateByType(date, picker);
    }, [date, picker]);

    const calculateNewValue = (key: string) => {
      if (
        picker === "12hours" &&
        flag &&
        calculatedValue.slice(1, 2) === "1" &&
        prevIntKey === "0"
      ) {
        return "0" + key;
      }

      return flag ? calculatedValue.slice(1, 2) + key : "0" + key;
    };

    // eslint-disable-next-line sonarjs/cognitive-complexity
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Tab") return;
      e.preventDefault();
      if (e.key === "ArrowRight") onRightFocus?.();
      if (e.key === "ArrowLeft") onLeftFocus?.();
      if (["ArrowUp", "ArrowDown"].includes(e.key)) {
        const step = e.key === "ArrowUp" ? 1 : -1;
        const newValue = getArrowByType(calculatedValue, step, picker);
        if (flag) setFlag(false);
        const tempDate = new Date(date);
        const updatedDate = setDateByType(tempDate, newValue, picker, period);

        // Update only the relevant part of the time string
        const newHours = updatedDate.getHours().toString().padStart(2, "0");
        const newMinutes = updatedDate.getMinutes().toString().padStart(2, "0");
        setTime(
          picker === "hours"
            ? `${newHours}:${minutes.toString().padStart(2, "0")}`
            : `${hours.toString().padStart(2, "0")}:${newMinutes}`,
        );
      }
      if (e.key >= "0" && e.key <= "9") {
        if (picker === "12hours") setPrevIntKey(e.key);

        const newValue = calculateNewValue(e.key);
        if (flag) onRightFocus?.();
        setFlag((prev) => !prev);
        const tempDate = new Date(date);
        const updatedDate = setDateByType(tempDate, newValue, picker, period);

        // Update only the relevant part of the time string
        const newHours = updatedDate.getHours().toString().padStart(2, "0");
        const newMinutes = updatedDate.getMinutes().toString().padStart(2, "0");
        setTime(
          picker === "hours"
            ? `${newHours}:${minutes.toString().padStart(2, "0")}`
            : `${hours.toString().padStart(2, "0")}:${newMinutes}`,
        );
      }
    };

    return (
      <Input
        ref={ref}
        id={id || picker}
        name={name || picker}
        className={cn(
          "w-[48px] text-center font-mono text-base tabular-nums caret-transparent [&::-webkit-inner-spin-button]:appearance-none",
          className,
        )}
        value={value || calculatedValue}
        onChange={(e) => {
          e.preventDefault();
          onChange?.(e);
        }}
        type={type}
        inputMode="decimal"
        onKeyDown={(e) => {
          onKeyDown?.(e);
          handleKeyDown(e);
        }}
        {...props}
      />
    );
  },
);

TimePickerSingleInput.displayName = "TimePickerInput";
