import { useMutation } from "@tanstack/react-query";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";

interface Props {
  apartmentId: number;
}

export const useRevalidateExternalApartment = () => {
  const apiClient = usePrivateApiClient();

  const { mutate, isPending } = useMutation({
    mutationFn: ({ apartmentId }: Props) =>
      apiClient
        .POST(`/private/stays/apartments/{id}/revalidate`, {
          params: {
            path: { id: apartmentId },
          },
        })
        .then((response) => response.data),

    onSuccess: () => {
      toast.success("Apartment has been refreshed successfully.");
    },
    onError: () => {
      toast.error("Apartment could not be refreshed.");
    },
  });

  return { revalidateExternalApartment: mutate, isPending };
};
